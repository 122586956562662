export default {
  "additionalQuestion": {
    "adultFemale": "Perempuan",
    "adultMale": "Pria",
    "adultOther": "Lainnya",
    "answerQuestions": "Bisakah Anda menjawab beberapa pertanyaan tambahan?",
    "checkinTime": "Waktu check-in",
    "checkoutTime": "Waktu check out",
    "moreDetailed": "Kami perlu tahu lebih banyak tentang masa tinggal Anda",
    "notificationFromFacility": "Pemberitahuan dari fasilitas",
    "selectTime": "Pilih waktu",
    "view": "Melihat",
    "customizeYourStayExp": "Sesuaikan pengalaman menginap Anda",
    "customizeYourStayExpDesc": "Maukah Anda menjawab beberapa pertanyaan tambahan?"
  },
  "turnstile": {
    "unsupportedBrowser": "Browser yang Anda gunakan tidak didukung. Silakan gunakan Chrome, Firefox, Safari, atau Microsoft Edge."
  },
  "base": {
    "account": "Akun",
    "all": "Semua",
    "apply": "Menerapkan",
    "attachFile": "Lampirkan file",
    "backTo": "Kembali ke {0}",
    "booking": "Pemesanan",
    "browserWarning": "Browser Anda tidak didukung secara resmi. Jika Anda mengalami kesulitan dalam proses pemesanan, harap upgrade browser Anda ke yang terbaru.",
    "cancel": "Membatalkan",
    "close": "Tutup",
    "confirm": "Konfirmasikan reservasi Anda",
    "continue": "Melanjutkan",
    "collapse": "Runtuh",
    "delete": "Menghapus",
    "edit": "Sunting",
    "error": "Ada yang salah",
    "error02": "Terjadi kesalahan selama pemrosesan. Silakan coba lagi nanti.",
    "errorPaymentMethod": "Tidak dapat melanjutkan proses pemesanan beberapa kamar dengan metode pembayaran berbeda. Silakan pilih kamar Anda dengan metode pembayaran yang sama atau pesan kamar Anda dalam urutan terpisah.",
    "oldErrorCode": {
      "1403": "Akun ini telah didaftarkan oleh fasilitas dan diharuskan untuk mereset kata sandi, silakan kembali ke halaman masuk dan tekan \"lupa kata sandi Anda\"."
    },
    "errorCode": {
      "1501": "Anda telah mencapai batas penggunaan kupon ini dan tidak dapat menerapkannya lagi",
      "1903": "Reservasi diterima {reservable_period_months} ​​bulan sejak hari ini.\nSilakan tentukan periode pemesanan.",
      "2304": "Pengaturan paket tidak sesuai dengan status akun Anda karena alasan yang tidak diketahui. Mohon segarkan halaman dan pesan ulang lagi.",
      "2401": "Anda hanya memiliki {currentPoint} poin. Tidak dapat membelanjakan {spendPoint} poin.",
      "3001": "Terjadi kesalahan batas waktu. Pembayaran belum diproses.",
      "3002": "Terjadi kesalahan, dan proses pembayaran gagal. Reservasi Anda belum selesai. Mohon coba lagi.",
      "3003": "Kartu ini tidak tersedia.",
      "3004": "Harap konfirmasi nomor kartu.",
      "3005": "Pastikan tanggal kedaluwarsa Anda sudah benar.",
      "3006": "Otentikasi 3D Secure gagal.",
      "3007": "Kartu kredit tidak diterima atau nomor kartu salah.",
      "3008": "Otentikasi 3D Secure telah dibatalkan.",
      "3009": "Otentikasi 3D Secure telah ditangguhkan karena beberapa permintaan.",
      "3011": "Terjadi kesalahan batas waktu. Pembayaran belum diproses.",
      "3013": "Memperbarui reservasi ini saat ini tidak tersedia karena pembaruan yang sedang berlangsung atau proses pembayaran. Silakan coba lagi dalam waktu kurang lebih 5 menit.",
      "3014": "Karena konten reservasi asli Anda telah diperbarui, harap batalkan dan konfirmasi ulang konten baru sebelum memperbaruinya.",
      "3016": "Memperbarui reservasi ini saat ini tidak tersedia karena pembaruan yang sedang berlangsung atau proses pembayaran. Silakan coba lagi dalam waktu kurang lebih 5 menit.",
      "3202": "Saldo poin Anda tidak tersedia.",
      "3203": "Terjadi kesalahan selama pemrosesan. Silakan coba lagi nanti.",
      "3204": "Proses pembayaran gagal, Silakan gunakan kartu lain, atau coba lagi nanti.",
      "3205": "Kartu ini tidak tersedia.",
      "3250": "Otentikasi 3D Secure gagal.",
      "3251": "Terjadi kesalahan selama pemrosesan. Silakan coba lagi nanti.",
      "3252": "Kartu yang dimasukkan tidak kompatibel dengan 3D Secure.",
      "3502": "Saldo poin Anda tidak tersedia.",
      "3504": "Gagal menunjuk kembali, harap konfirmasi riwayat poin Anda.",
      "3750": "Tanggal-tanggal ini hanya untuk acara lotere. Silakan periksa situs web hotel untuk rincian lebih lanjut.",
      "3751": "Acara lotere ini tidak tersedia saat ini, silakan periksa situs web hotel untuk lebih jelasnya",
      "3752": "Acara lotere ini tidak tersedia saat ini, silakan periksa situs web hotel untuk lebih jelasnya",
      "3753": "Acara lotere ini tidak tersedia saat ini, silakan periksa situs web hotel untuk lebih jelasnya",
      "3754": "Acara lotere ini tidak tersedia saat ini, silakan periksa situs web hotel untuk lebih jelasnya",
      "3756": "Anda sudah mengikuti lotere ini. Silakan klik \"Reservasi\" dan navigasikan ke bagian \"Lotre\" di halaman profil anggota Anda untuk melihat entri Anda.",
      "3757": "Acara lotere ini tidak tersedia saat ini, silakan periksa situs web hotel untuk lebih jelasnya",
      "3760": "Acara lotere ini tidak tersedia saat ini, silakan periksa situs web hotel untuk lebih jelasnya",
      "3780": "Tautan Anda kedaluwarsa atau tidak valid, silakan kunjungi kembali situs hotel dan akses kembali acara undian ini.",
      "3781": "Tautan Anda kedaluwarsa atau tidak valid, silakan kunjungi kembali situs hotel dan akses kembali acara undian ini.",
      "3782": "Tautan Anda kedaluwarsa atau tidak valid, silakan kunjungi kembali situs hotel dan akses kembali acara undian ini.",
      "3783": "Tautan Anda kedaluwarsa atau tidak valid, silakan kunjungi kembali situs hotel dan akses kembali acara undian ini."
    },
    "sessionExpired": "Sesi berakhir. Silakan refresh halaman untuk melanjutkan.",
    "notAvailableYet": "",
    "fileLimit80MB": "Ukuran file unggahan maksimum 80MB per file gambar",
    "upTo30FilesError": "Tidak dapat melampirkan lebih dari 30 file",
    "upTo30Files": "Hingga 30 file",
    "textLengthLimit": "Teks jawaban harus terdiri dari 255 karakter atau kurang.",
    "triplaCoLtd": "tripla Co., Ltd.",
    "triplaAi": "tripla.ai",
    "GDPR": "Kebijakan GDPR",
    "here": "Di Sini",
    "imageError": "Gambar gagal dimuat.",
    "join": "Mendaftar",
    "languages": {
      "en": "Bahasa inggris",
      "ja": "日本語",
      "ko": "한국어",
      "zh_Hans": "简体中文",
      "zh_Hant": "繁體中文"
    },
    "loading": "Mencari...",
    "search": "Mencari",
    "login": "Masuk",
    "logout": "Keluar",
    "logoutMessage": "Anda sudah keluar",
    "signInStatusChangeMessage": "Status Masuk telah diubah dan Anda akan diarahkan ke halaman pencarian.",
    "memberSignIn": "Masuk keanggotaan",
    "memberSignUp": "Mendaftar",
    "next": "Berikutnya",
    "notSelected": "Tidak terpilih",
    "noData": "Tidak ada data",
    "optional": "opsional",
    "pay": "Membayar",
    "previous": "Sebelumnya",
    "previousPage": "halaman sebelumnya",
    "privacyPolicy": "Kebijakan pribadi",
    "readMore": "Baca selengkapnya",
    "readLess": "Baca lebih sedikit",
    "recommended": "Direkomendasikan",
    "remove": "Menghapus",
    "required": "diperlukan",
    "requireMessage": "Bidang yang wajib diisi tidak boleh kosong.",
    "reservationStatuses": {
      "cancelled": "Dibatalkan",
      "done": "Disimpan"
    },
    "save": "Menyimpan",
    "signInUp": "Login / Daftar",
    "submit": "Kirim",
    "termError": "Mohon terima syarat dan ketentuannya",
    "taxMessage": "Tarif pajak konsumsi saat ini sebesar 8%, namun diperkirakan akan dinaikkan menjadi 10% pada bulan Oktober 2019. Untuk pemesanan akomodasi setelah bulan Oktober, silakan langsung merujuk ke jumlah total di layar konfirmasi reservasi. Kami menghargai kerja sama dan pengertian Anda.",
    "termsOfUse": "Syarat Penggunaan",
    "topPage": "Halaman atas",
    "update": "Memperbarui",
    "success": "Kesuksesan",
    "unsubscribe": "Berhenti berlangganan",
    "unsubscribeConfirmation": {
      "error": "Kesalahan. Silakan coba lagi nanti.",
      "title": "Kami sedih melihat Anda pergi. ",
      "description": "Jika Anda masih ingin berhenti berlangganan email promosi kami, silakan klik tombol."
    },
    "unsubscribeSuccessDescribe": "Anda telah berhenti berlangganan. Kami tidak akan mengirimi Anda email promosi kami lagi.",
    "warning": {
      "kidsChanged": "Fasilitas ini tidak mendukung kondisi pencarian yang ditentukan, sehingga kondisi pencarian untuk anak-anak telah diubah."
    },
    "formalName": "{name}",
    "maintenanceTitle": "Situs saat ini sedang dalam pemeliharaan",
    "maintenanceDesc": "Kami mohon maaf atas ketidaknyamanan yang ditimbulkan. Kami hampir selesai.",
    "noResultAvailable": "Tidak ada hasil pencarian yang cocok",
    "taxAndServiceFeeIncluded": "Termasuk pajak dan biaya layanan"
  },
  "roomLottery": {
    "updateLottery": "Perbarui entri lotere Anda",
    "restoreLottery": "Pulihkan entri lotere Anda",
    "updateDisabledDesc": "Masa masuk undian ini berakhir, harap menunggu hasilnya.",
    "viewUpdateDetails": "Lihat/Perbarui detail",
    "restoreUpdateEntry": "Pulihkan/Perbarui Entri",
    "updateProfileDesc": "Setelah memenangkan lotre dan melakukan reservasi, informasi yang diberikan di atas akan berlaku dan tidak dapat diubah.",
    "cancellationEmailDesc": "Kami telah mengirimkan email pembatalan ke {0}",
    "cancelModalTitle": "Batalkan entri lotere Anda",
    "cancelModalDesc": "Apakah Anda ingin membatalkan entri lotere Anda? Tindakan ini tidak bisa dibatalkan.",
    "cancelEntry": "Batalkan Entri",
    "reservationDeadlinePassed": "Batas waktu reservasi telah lewat",
    "reservationDeadlinePassedDesc": "Kami mohon maaf, namun batas waktu pemesanan telah lewat. Untuk lebih jelasnya, silakan kunjungi situs web hotel atau hubungi kami secara langsung",
    "accountNotMatched": "Rekening ini tidak sesuai dengan yang digunakan pada saat pengajuan undian. Silakan masuk dengan akun yang benar.",
    "resultDateNotice": "Hasilnya akan diumumkan pada {date} ({day}) {time} ~",
    "resultDateNotice02": "Hanya pemenang terpilih yang akan menerima email lain untuk melanjutkan proses pemesanan sebenarnya.",
    "resultDateNotice03": "Anda selalu dapat memeriksa status di bawah \"Reservasi\" di menu keanggotaan.",
    "lotteryEntryNumber": "Nomor entri lotere",
    "lotteryEntryConfirmed": "Entri lotere Anda telah dikonfirmasi",
    "lotteryEntryCancelled": "Entri lotere Anda telah dibatalkan",
    "lotteryResultNotSelected": "Anda tidak terpilih untuk acara lotere ini.",
    "notSelectedNotice": "Terima kasih telah bergabung, kami berharap dapat bertemu Anda di lain waktu.",
    "lotteryResultSelectedNotified": "🎉 Anda telah terpilih sebagai PEMENANG!!",
    "lotteryWinnerNotice01": "※ Reservasi belum dikonfirmasi. Silakan selesaikan prosedur reservasi.",
    "lotteryWinnerNotice02": "Batas waktu reservasi hingga {date} ({day}) {time}",
    "manageYourLottery": "Kelola lotere",
    "reservationIncomplete": {
      "errorMessage": "Proses lain sedang berlangsung. Harap setujui untuk membatalkan jika Anda ingin melanjutkan.",
      "checkboxMessage": "Saya setuju untuk membatalkan proses ini.",
      "modalMessage": "Proses reservasi sudah selesai dan hasilnya akan ditampilkan."
    }
  },
  "bookingDetail": {
    "overlappedReservation": "Anda telah menyelesaikan {0} yang tumpang tindih dengan tanggal check-in dan check-out yang Anda pesan sekarang, harap konfirmasi ulang sebelum melakukan reservasi.\n*Reservasi yang tumpang tindih mungkin berasal dari fasilitas lain di bawah merek hotel ini.",
    "overlappedReservationLink": "reservasi",
    "overlappedReservationGuest": "Anda telah menyelesaikan reservasi yang tumpang tindih dengan tanggal check-in dan check-out yang Anda pesan sekarang, harap konfirmasi ulang sebelum melakukan reservasi.\n*Reservasi yang tumpang tindih mungkin berasal dari fasilitas lain di bawah merek hotel ini.\n*jika Anda pernah membatalkan pemesanan melalui perangkat lain sebelumnya, deteksi ini mungkin salah, harap konfirmasi melalui email yang Anda terima.",
    "overlappedReservationGuest2": "*jika Anda telah membatalkan pemesanan melalui perangkat lain atau menghapus cookie, deteksi ini mungkin salah, harap konfirmasi melalui email yang Anda terima."
  },
  "bookingResult": {
    "paymentPromotionBanner": {
      "description": "Diskon ini berlaku untuk pemesanan yang dilakukan di situs web kami dan dibayar terlebih dahulu dengan kartu kredit.",
      "message": "Nikmati diskon {0} eksklusif bila Anda membayar dengan kartu kredit Anda.",
      "limitedTimeOnly": "Penawaran hanya berlaku untuk waktu terbatas!",
      "title2": "Bayar dengan Kartu Kredit Anda & Hemat!",
      "title3": "Berhak untuk mendapatkan Diskon Kartu Kredit sebesar {0}"
    },
    "allUser": "Semua",
    "availableBetween": "Tersedia Antara",
    "bath": "Mandi",
    "shower": "Mandi",
    "bathtub": "Bak mandi",
    "bedCount": "{n} tempat tidur | {n} tempat tidur | {n} tempat tidur",
    "book": "Pesan",
    "bookAirline": "Penerbangan + Kamar",
    "flightRooms": "Penerbangan + Kamar",
    "bookAirlineWarning": "{0} opsi hanya tersedia untuk tanggal check-in {1}. Silakan sesuaikan tanggal check-in Anda untuk mengakses opsi ini.",
    "bookAirlineWarningStay": "{0} opsi hanya tersedia untuk masa menginap maksimal {1} ​​hari. Silakan sesuaikan periode menginap Anda untuk mengakses opsi ini.",
    "cancellationFreeUntil": "Pembatalan gratis hingga {0} (waktu setempat)",
    "cancellationPolicy": "Kebijakan Pembatalan",
    "discountAmount": "Jumlah diskon",
    "excludeDuring": "Kecualikan Selama",
    "forNights": "selama {n} malam | selama {n} malam | selama {n} malam",
    "freeWifi": "WiFi gratis di kamar",
    "followingSearchConditions": "Ada kamar yang tersedia pada tanggal ini.",
    "guests": "Tamu",
    "includes": "Termasuk",
    "planInformation": "Informasi rencana",
    "lowestPriceInfo": "Harga terendah mungkin tidak ditampilkan di kalender tergantung pada status inventaris dan kondisi diskon. Silakan periksa harga sebenarnya dari kalender.",
    "maxAdults": "Jumlah maksimum orang dewasa adalah {n}.",
    "meals": "Makanan",
    "memberExclusive": "Eksklusif anggota",
    "membersOnly": "Hanya Anggota",
    "nonSmoking": "Bebas Rokok",
    "noRoomFound": "Maaf pencarian Anda tidak menghasilkan kamar yang tersedia.",
    "tryChanging": "Coba ubah {0} atau lokasi Anda.",
    "tryItem": "tanggal",
    "noRoomSelected": "Anda belum memilih ruangan mana pun",
    "noRoomsAvailable": "Maaf pencarian Anda tidak menghasilkan kamar yang tersedia.",
    "noRoomsAvailableDesc": "Coba ubah tanggal atau lokasi Anda.",
    "notAvailable": "Tidak tersedia",
    "priceFor": "Harga untuk",
    "priceForAdult": "{n} orang dewasa | {n} orang dewasa | {n} orang dewasa",
    "priceForChild": "{n} anak | {n} anak | {n} anak-anak",
    "priceForRoom": "Ruang",
    "paymentCreditCard": "Pembayaran online dengan kartu kredit",
    "paymentMethods": "Pembayaran",
    "paymentMethod": {
      "on_site": "Pembayaran di Tempat",
      "credit_card": "Pembayaran online dengan kartu kredit",
      "on_site_credit_card_required": "Pembayaran di Tempat (Registrasi kartu kredit diperlukan)",
      "deferred_payment": "Pembayaran yang ditangguhkan",
      "credit_card_full": "Pembayaran penuh dengan kartu kredit",
      "credit_card_deposit": "Setoran dengan kartu kredit",
      "bank_transfer": "transfer Bank",
      "credit_card_krw_domestic": "Pembayaran online (kartu kredit domestik Korea)",
      "krw_domestic_description": "Lotte Cards saat ini tidak tersedia untuk digunakan tetapi akan segera tersedia.",
      "credit_card_krw_international": "Pembayaran online (kartu kredit internasional)"
    },
    "paymentNewCreditCards": "Pembayaran Online melalui kartu kredit baru",
    "paymentRegisteredCreditCards": "Pembayaran online melalui kartu kredit terdaftar",
    "planDetails": "Detail rencana",
    "plansLimitForUpdating": "Anda tidak dapat memilih paket kecuali yang ada di reservasi awal.",
    "price": "Harga",
    "promotionMayChanged": "Promosi dapat diubah atau ditutup tanpa peringatan.",
    "registerCard": "Daftarkan kartu ini",
    "regularRate": "Tarif Reguler",
    "reservationStartDate": "Tanggal mulai reservasi",
    "showRoomDetails": "Tampilkan detail ruangan",
    "roomDetails": "Detail kamar",
    "roomFeatures": "Informasi kamar",
    "roomLeft": "Tidak ada ruang tersisa | Hanya tersisa {n} ruangan! | Hanya tersisa {n} ruangan!",
    "roomAvailable": "Tidak ada ruang tersisa | {n} ruangan tersisa | {n} ruangan tersisa",
    "roomEnough": "Tersedia",
    "roomRate": "Kecepatan",
    "rooms": "Kamar",
    "roomSize": "Ukuran ruangan",
    "roomSizeUnit": {
      "tatami_mats": "tikar tatami"
    },
    "select": "Pilih",
    "selectNewDate": "Pilih tanggal baru",
    "serviceFeeDesc": "Harga ini termasuk biaya layanan yang diterapkan oleh situs pemesanan.",
    "sleeps": "Tamu",
    "smoking": "Merokok",
    "stopReservationUpdate": "Hentikan proses pembaruan reservasi",
    "stopLotteryEntryRestore": "Hentikan proses pemulihan lotere",
    "stopLotteryEntryUpdate": "Hentikan proses pembaruan lotere",
    "targetUser": "Pengguna Sasaran",
    "taxExcluded": "Tidak termasuk pajak",
    "taxIncluded": "Termasuk pajak",
    "toilet": "Toilet",
    "totalPrice": "",
    "totalPriceForNights": "Total harga untuk {n} malam | Total harga untuk {n} malam | Harga total untuk {n} malam",
    "viewAll": "Lihat semua",
    "viewClose": "Lihat lebih sedikit",
    "viewGallery": "Lihat semua foto",
    "viewLess": "Lihat lebih sedikit",
    "viewMore": "Lihat semua paket {n} | Lihat semua {n} paket",
    "viewAllRooms": "Lihat semua {n} ruangan | Lihat semua {n} ruangan",
    "wifi": "Wifi",
    "wifiOption1": "Tidak tersedia",
    "wifiOption2": "Bebas",
    "wifiOption3": "Mengenakan biaya",
    "searchResults": "Hasil Pencarian:",
    "nPlans": "{n} rencana | {n} rencana",
    "nRoomTypes": "{n} tipe kamar | {n} tipe kamar"
  },
  "customAuth": {
    "failTitle": "Gagal masuk.",
    "failDesc": "Gagal login karena informasi keanggotaan tidak terdaftar."
  },
  "cancellationPolicy": {
    "policyHeader": "Biaya pembatalan berikut akan berlaku untuk pemesanan Anda:",
    "policyLine": "{0} hari sebelum check-in: {1}% dari tarif.",
    "policyLineWithTime": "{0} hari sebelum check-in, setelah {1}: {2}% dari tarif.",
    "freePolicyLine": "{0} hari sebelum check-in: gratis.",
    "noShowLine": "Ketidakhadiran: {0}% dari tarif.",
    "cancelOnTheDay": "Pada hari check-in: {0}% dari tarif.",
    "cancelOnTheDayFree": "Pada hari check-in sebelum tanggal {0}: gratis.",
    "cancelOnTheDayCharge": "Pada hari check-in setelah tanggal {0}: {1}% dari tarif.",
    "cancelBeforeOnTheDayCharge": "Pada hari check-in sebelum tanggal {0}: {1}% dari tarif.",
    "uponLocalTime": "*Semua kebijakan pembatalan dihitung berdasarkan waktu setempat.",
    "uponLocalTimeOTA": "Semua kebijakan pembatalan dihitung berdasarkan waktu setempat.",
    "ifBookedElsewhere": "Jika Anda membuat reservasi melalui situs web atau agen perjalanan lain, harap batalkan dari sumber asli yang Anda gunakan.",
    "specialRate": "Tarif pembatalan khusus sebagai: {0}",
    "guest": "Tamu",
    "policyWithTimeEachDayCharge": "{0} hari sebelum check-in, setelah {1}: {2}% dari tarif. ",
    "policyWithBeforeTimeEachDayCharge": "{0} hari sebelum check-in, sebelum {1}: {2}% dari tarif.",
    "policyWithTimeEachDayFree": "{0} hari sebelum check-in, sebelum {1}: gratis"
  },
  "calendar": {
    "fullyBooked": "sudah terpesan semua",
    "roomsLeft": "# Kamar tersisa",
    "selected": "Terpilih",
    "unavailable": "Tidak tersedia",
    "unavailableNow": "Tidak tersedia sekarang"
  },
  "corporateAdmin": {
    "admin": "Admin",
    "checkInDate": "Tanggal check-in",
    "download": "Unduh",
    "guestName": "Nama pemesanan",
    "hotelName": "Nama hotel",
    "maxNumberStay": "Jumlah masa inap maksimum",
    "maxNumberStays": "Jumlah masa inap maksimum",
    "nMonths": "{n} Bulan | {n} Bulan | {n} Bulan",
    "nights": "malam",
    "numberOfRooms": "Kamar",
    "remaining": "Tersisa",
    "reservationData": "Data reservasi",
    "reservationDataDesc1": "※ Detail penggunaan menunjukkan jumlah total malam yang dipesan menggunakan situs web kami. Oleh karena itu, harap diperhatikan bahwa pemesanan lainnya (telepon, dll.) tidak ditampilkan.",
    "reservationDataDesc2": "※ Mungkin diperlukan waktu beberapa menit sejak Anda membuat reservasi hingga isinya terlihat di halaman ini.",
    "reservationDataDesc3": "※ Perubahan/pembatalan yang dilakukan di luar situs web kami tidak terlihat di layar ini.",
    "reservationDate": "Tanggal pemesanan",
    "reservationNumber": "Nomor pendaftaran",
    "reserved": "Disimpan",
    "status": "Status",
    "title": "Admin Perusahaan",
    "totalPrice": "Kecepatan",
    "period": "Periode",
    "year": "Tahun"
  },
  "creditCardForm": {
    "enterYourPaymentDetails": "Masukkan detail pembayaran Anda",
    "addNewCard": "Kartu kredit baru",
    "cardExpiration": "Tanggal habis tempo",
    "cardExpirationConfirm": "Harap konfirmasi tanggal kedaluwarsa",
    "cardHolder": "Nama pemegang kartu",
    "cardHolderConfirm": "Harap konfirmasi nama pemegang kartu",
    "cardHolderFormatError": "Silakan masukkan nama Anda dalam karakter Romawi setengah lebar.",
    "cardHolderHelpOTA": "Masukkan nama lengkap seperti yang tertera di kartu Anda",
    "cardHolderHelp": "Masukkan nama lengkap yang tercetak di kartu Anda",
    "cardNumber": "Nomor kartu",
    "cardNumberConfirm": "Harap konfirmasi nomor kartu",
    "cardNumberHelp": "Masukkan nomor kartu Anda",
    "cardRequired": "Informasi kartu kredit Anda hanya digunakan untuk menjamin pemesanan Anda dan membebankan biaya pembatalan berdasarkan {0}.",
    "cardSecurityCode": "Kode keamanan",
    "cardSecurityCodeConfirm": "Harap konfirmasi kode keamanan",
    "cardSecurityCodeHelp": "CVV",
    "ccvExampleOTA": "Nomor 3 atau 4 digit yang terletak di bagian belakang kartu (Visa, Mastercard, JCB, dan Diners Club) atau di bagian depan kartu (American Express).",
    "ccvExample": "Ini adalah nomor 3 atau 4 digit yang tercetak di bagian belakang (Visa, Mastercard, JCB) atau depan (American Express) kartu. Ini digunakan untuk pembelian online untuk memverifikasi bahwa Anda adalah pemegang kartu yang sah dan mencegah penipuan.",
    "creditCard": "Kartu kredit",
    "expiredError": "Harap daftarkan kartu kredit yang valid sebelum tanggal check-out.",
    "month": "Bulan",
    "payInAdvanceOTA": "Pembayaran kartu kredit diproses oleh {0}.",
    "payInAdvance": "Bayar di muka dengan kartu kredit, diproses oleh {0}.",
    "payInAdvanceVoucher": "Pembayaran di muka dengan kartu kredit, diproses oleh {0}.",
    "payByCreditCard": "Pembayaran akan diselesaikan oleh agen perjalanan ({0}) pada saat reservasi selesai.",
    "payByCreditCardConsent": "Informasi pribadi Anda akan dibagikan kepada agen perjalanan, tripla Co., Ltd. Dengan melanjutkan reservasi ini, Anda setuju untuk membagikan informasi Anda kepada {0}, {1}, dan {2}.",
    "tappayNote": "Kami menggunakan sistem transaksi SLL TapPay 2048-bit. Kami tidak menyimpan detail kartu pelanggan apa pun. TapPay mengamankan informasi kartu kredit Anda dengan sertifikasi sesuai dengan PCI DSS di Penyedia Layanan Tingkat 1.",
    "year": "Tahun",
    "cardBrand": "Tipe kartu",
    "cardBrandMismatch": "Nomor kartu bukan milik jenis kartu yang dipilih",
    "paymentOverMillionError": "VISA dan Mastercard tidak dapat digunakan untuk pembayaran online di atas 1 juta yen. Silakan gunakan merek kartu lain atau pilih jenis pembayaran lain.",
    "badCards": "Karena perubahan sistem, kartu kredit berikut tidak dapat digunakan untuk pemesanan baru. Silakan masukkan informasi kartu lagi.",
    "gmoBadCards": "Karena perubahan sistem, kami menonaktifkan sementara opsi untuk menyimpan atau memilih kartu kredit sebelumnya. Untuk pembayaran kartu kredit, silakan masukkan rincian kartu setiap kali. Kami mohon maaf atas ketidaknyamanan ini dan menghargai pengertian Anda.",
    "pontaVisaCheckbox": "Saat menggunakan kartu Route Inn Hotels PontaVISA, silakan pilih 'Kartu Route Inn Hotels PontaVISA' sebagai merek kartu. Jika Anda memilih 'VISA', poin bonus tidak akan diberikan.",
    "creditCardInvalid": "Tidak sah",
    "creditCardInvaildHoverText": "Karena perubahan sistem, kartu kredit berikut tidak dapat digunakan untuk pemesanan baru. Silakan masukkan informasi kartu lagi.",
    "rrn": "",
    "firstTwoDigitOfPin": "",
    "payDirectlyOnsitePayment": "Bayar langsung di hotel dengan pembayaran di tempat.",
    "payByLocalCurrency": "Bayar dalam mata uang lokal menggunakan kartu JCB, Amex, atau Diners Anda, atau langsung di hotel dengan pembayaran di tempat.",
    "exploreLocalCurrency": "Atau jelajahi opsi pembayaran lain dalam mata uang lokal.",
    "exploreOther": "Atau jelajahi opsi pembayaran lain."
  },
  "gmoPaypalOptions": {
    "null": "Pilih jenis kartu",
    "visa": "VISA",
    "ponta_visa": "VISA (kartu Route Inn Hotel PontaVISA)",
    "master": "MasterCard",
    "mastercard": "MasterCard",
    "amex": "American Express",
    "jcb": "JCB",
    "diners": "Klub Pengunjung"
  },
  "downloadPasswordModal": {
    "label": "Silakan masukkan kata sandi enkripsi. Reservasi akan di-zip menggunakan kata sandi"
  },
  "extraItems": {
    "error": {
      "extraNumber": "Silakan ubah nomor {name}. Nomor yang dapat dipesan adalah {min} ~ {max}."
    }
  },
  "extras": {
    "dateSelectTitle": "Pilih Tanggal untuk Menambahkan Ekstra",
    "dateSelectBtn": "Lanjutkan ke Kuantitas",
    "goToGuestDetails": "Masukkan Detail Tamu",
    "stock": "Saham:",
    "selectableQuantity": "Kuantitas yang dapat dipilih",
    "remainingExtras": "Ekstra yang Tersisa"
  },
  "facilitySuggestion": {
    "distanceFrom": "Jarak: {n}km dari fasilitas ini",
    "from": "Dari",
    "header": "Kamar lain yang tersedia di dekat hotel ini",
    "soldOut": "Terjual habis. Silakan ubah kebutuhan Anda dan cari lagi.",
    "soldOutDesc": "Teman kencanmu populer. Kami kehabisan kamar di properti ini! Coba sesuaikan tanggal Anda atau pilih properti lain.",
    "visitHotelWebsite": "Kunjungi Situs Web Hotel"
  },
  "guestDetail": {
    "afterUpdate": "Diperbarui",
    "bookingForSomeone": "Saya sedang membuat pemesanan untuk orang lain.",
    "brrDiscount": "Diskon tarif kamar terbaik",
    "confirmUpdates": "Konfirmasikan pembaruan",
    "confirmPayByCard": "Konfirmasi Pembayaran dengan Kartu Kredit",
    "coupon": "Kupon",
    "couponApplied": "Diterapkan",
    "couponAppliedNew": "Kupon yang berlaku:",
    "creansmaerdCouponApplied": "Saat ini nama kupon {name} diterapkan.",
    "couponCode": "Kode Kupon",
    "couponDiscount": "Diskon kupon",
    "couponExpired": "Mohon maaf, kode kupon Anda tidak berlaku lagi.",
    "couponInvalid": "Kode kupon yang Anda masukkan salah.",
    "couponLimitReached": "Kupon {code} ini tidak dapat digunakan lagi karena batas penggunaan telah tercapai.",
    "couponMaxStays": "Diskon kupon ini hanya dapat digunakan maksimal {n} malam pertama. Setiap malam setelah {n} malam pertama akan dikenakan harga reguler.",
    "couponTargetPlans": "Paket target kode kupon",
    "couponNotUse": "Jangan gunakan kupon apa pun",
    "couponValidUntil": "Berlaku hingga tanggal {tanggal}",
    "couponUsageQuantityLimit": "Hanya satu kode kupon yang dapat diterapkan dalam satu waktu. Harap hapus kode yang sudah ditambahkan untuk menerapkan kode baru.",
    "creditCardWarning": "Transaksi pembayaran awal kartu kredit Anda akan dibatalkan dan total jumlah pembayaran baru akan dibebankan ke kartu kredit Anda.",
    "availableCoupons": "Kupon Tersedia ",
    "viewMoreCoupons": "Lihat lebih banyak Kupon",
    "removeCoupon": "Hapus",
    "day": "Hari {n}",
    "email": "Email (Izinkan menerima email dari {email})",
    "emailAgain": "Konfirmasi email)",
    "emailAgainConfirm": "\"Email\" dan \"Konfirmasi Email\" tidak cocok",
    "emailAgainHelp": "Masukkan alamat email Anda lagi",
    "emailConfirm": "Silakan masukkan alamat email yang benar",
    "emailHelp": "Masukkan alamat email Anda",
    "extras": "Ekstra",
    "extraLeft": "{count} {unit} tersisa",
    "extraOutOfStock": "Stok Habis",
    "fullName": "Nama lengkap",
    "fullNameError": "Harap berikan nama lengkap dalam bahasa Inggris",
    "goPreviousScreenConfirm": "Kembali ke layar sebelumnya?",
    "goPreviousScreenDescription": "Harap dicatat bahwa informasi apa pun yang dimasukkan pada halaman ini tidak akan disimpan.",
    "goPreviousScreenContinue": "Melanjutkan",
    "guestDetails": "Detail tamu",
    "guestName": "Nama tamu",
    "guestNameError": "Harap berikan nama lengkap dalam bahasa Inggris",
    "guestNameHelp": "Masukkan nama lengkapmu",
    "guestNameKanji": "Nama tamu (Kanji)",
    "guestStayName": "Nama tamu",
    "mainGuest": "kontak besar",
    "notes": "Catatan",
    "originalPaymentAmount": "Jumlah pembayaran asli",
    "promotionDiscount": "Diskon",
    "phone": "Telepon",
    "phoneConfirm": "Silakan masukkan nomor telepon yang benar. Hanya angka dan + yang diperbolehkan.",
    "phoneHelp": "Masukkan nomor telepon Anda",
    "priceBecomeCheaper": "Harga paket yang Anda pilih kini menjadi lebih murah karena hotel memperbarui promosi. Silakan periksa harga terbaru dan lanjutkan pemesanan Anda.",
    "priceBecomeExpensive": "Harga paket yang Anda pilih kini menjadi lebih mahal karena hotel memperbarui promosi. Silakan periksa harga terbaru dengan cermat dan lanjutkan pemesanan Anda.",
    "priceDiff": "Perbedaan harga",
    "signupTerms": "Sistem pemesanan ini disediakan oleh tripla. Informasi pribadi Anda akan dibagikan dengan tripla. Dengan melanjutkan pendaftaran keanggotaan ini, saya menyetujui {0}, {1} dan {2} tripla.",
    "terms": "Dengan menyelesaikan reservasi ini, Anda mengakui bahwa informasi pribadi Anda akan dibagikan dengan tripla Co., Ltd, perusahaan yang menyediakan sistem reservasi ini. Anda setuju untuk membagikan informasi Anda dengan tripla {0}, {1}, dan {2}.",
    "totalAmount": "Jumlah total",
    "totalAmountWithTax": "Jumlah total (termasuk pajak)",
    "totalWithoutTax": "Jumlah total (Tidak termasuk pajak)",
    "updateUserInformation": "Perbarui profil keanggotaan saya dengan perubahan di atas kecuali email.",
    "cheaperAccommodationTax": "Pajak akomodasi untuk reservasi Anda kini menjadi lebih murah sejak Anda menukarkan poin. Silakan periksa harga terbaru dan lanjutkan pemesanan Anda",
    "roomGuestName": "Kamar {0} Nama Tamu",
    "confirmation": {
      "title": "Bidang yang ditandai dengan {0} wajib diisi untuk melengkapi formulir.",
      "description": "Nama tamu tidak dapat diubah setelah reservasi dilakukan. Harap pastikan untuk memasukkan nama orang yang akan menginap."
    }
  },
  "inventoryCalendar": {
    "availableIconDescription": "{icon}: Ruangan tersedia",
    "unavailableIconDescription": "{icon}: Tidak ada ruangan yang tersedia",
    "uncertainIconDescription": "{icon}: Ketersediaan terbatas"
  },
  "lineLogin": {
    "buttonText": "Masuk dengan LINE"
  },
  "meta": {
    "facilities": "Fasilitas Merek",
    "sign_in": "Masuk",
    "organization_sign_in": "Masuk perusahaan",
    "sign_up": "Mendaftar",
    "search_results": "Hasil Pencarian",
    "guest_detail": "Detil Tamu",
    "payment_method": "Pembayaran",
    "lotteryCompleted": "Konfirmasi Entri Lotere",
    "completed": "Konfirmasi",
    "reservationIncomplete": "Reservasi Tidak Lengkap",
    "my_account": "Halamanku",
    "otaHome": "pilihan hotel cerdas dengan pencarian bertenaga AI",
    "otaHomeDescription": "Jelajahi 2000+ akomodasi pilihan dengan tripla, didukung oleh pencarian AI. Dari ryokan tradisional hingga resor mewah, temukan penginapan sempurna Anda dengan mudah.",
    "otaSearchHotels": "Hasil Pencarian Hotel",
    "otaSearchResults": "Hasil Pencarian",
    "otaGuestDetail": "Detil Tamu",
    "otaPayment": "Pembayaran",
    "otaConfirmation": "Konfirmasi",
    "otaBookingConfirmation": "Konfirmasi pemesanan"
  },
  "membership": {
    "promotionRate": "Tingkat promosi",
    "regularRate": "Tarif Reguler",
    "signUpFor": "Tingkat Keanggotaan"
  },
  "membershipForm": {
    "address": "Alamat",
    "alreadyHaveAccount": "Sudah memiliki akun",
    "back": "Kembali",
    "buildingName": "Nama Gedung dan nomor ruangan",
    "changePassword": "Ganti kata sandi",
    "changePasswordResult": "Kata sandi Anda telah diubah",
    "changePasswordResult02": "✓ Berhasil memperbarui kata sandi Anda.",
    "changePasswordResult03": "Klik Masuk untuk masuk menggunakan kata sandi baru kami",
    "changePasswordSecondary": "Silakan masukkan kata sandi baru Anda",
    "confirmDeletedResult": "Akun Anda telah berhasil dihapus",
    "confirmNewPassword": "Konfirmasi password baru",
    "confirmPassword": "Konfirmasi sandi",
    "confirmRegisterFail": "Ada yang salah",
    "confirmRegisterFail02": "Silakan mendaftar lagi",
    "confirmRegisterResult": "Otentikasi alamat email",
    "confirmRegisterResult02": "✓ Berhasil mendaftar",
    "confirmRegisterResult03": "Nikmati masa tinggal Anda",
    "createAccount2": "buat akunmu",
    "createAccount3": "Buat Akun",
    "createAccount4": "membuat akun baru",
    "dob": "Tanggal lahir",
    "email": "Surel",
    "emailError": "Silahkan konfirmasi email Anda",
    "emailPromotionCheck": "Terima email promosi dari hotel.",
    "english_error": "Silakan masukan dalam bahasa Inggris.",
    "locale_error": {
      "ja": "Silakan masukan dalam bahasa Inggris.",
      "zh_Hant": "Silakan masukan dalam bahasa Inggris.",
      "ko": "Silakan masukan dalam bahasa Inggris."
    },
    "enterDetail": "Masukkan detail Anda di bawah.",
    "first_name": "Nama depan",
    "firstNameLocales": {
      "ja": "Nama depan",
      "zh_Hant": "Nama depan",
      "ko": "Nama depan"
    },
    "first_name_kanji": "",
    "first_name_kanji_placeholder": "",
    "first_name_placeholder": "Yohanes",
    "firstNamePlaceHolderLocales": {
      "ja": "Yohanes",
      "zh_Hant": "Yohanes",
      "ko": "Yohanes"
    },
    "firstName": "Nama depan",
    "firstNameError": "Harap konfirmasi nama depan Anda",
    "firstNameKana": "Nama depan",
    "forgotPassword": "Lupa kata sandi Anda",
    "forgotPassword02": "Setel ulang kata sandi",
    "forgotPasswordError": "Tidak ada pengguna yang cocok dengan email yang diberikan.",
    "forgotPasswordResult": "Setel ulang kata sandi",
    "forgotPasswordResult02": "✓ Email pengaturan ulang kata sandi telah dikirim.",
    "forgotPasswordResult02New": "✓ Permintaan pengaturan ulang kata sandi diterima untuk {email}.",
    "forgotPasswordResultSecondary": "Email pengaturan ulang kata sandi telah dikirim ke alamat email yang tercatat untuk akun Anda, namun mungkin memerlukan waktu beberapa menit untuk muncul di kotak masuk Anda. Harap tunggu setidaknya 10 menit sebelum mencoba mengatur ulang lagi.",
    "forgotPasswordResultSecondaryNew": "Jika email yang Anda masukkan dikaitkan dengan sebuah akun, Anda akan segera menerima email pengaturan ulang kata sandi. Mungkin diperlukan waktu hingga 10 menit hingga pesan sampai. Silakan periksa folder spam atau sampah Anda sebelum mencoba mengatur ulang lagi.",
    "forgotPasswordResultSecondary02New": "Jika Anda tidak menerima email dalam waktu 10 menit, Anda mungkin tidak memiliki akun. Coba email lain atau {0}.",
    "forgotPasswordSecondary": "Silakan masukkan alamat email Anda. Kami akan mengirimkan tautan reset ke akun email Anda.",
    "gender": "Jenis kelamin",
    "generalError": "Terjadi kesalahan selama pemrosesan. Silakan coba lagi nanti.",
    "guest_first_name": "Nama depan",
    "guest_first_name_kanji": "",
    "guest_last_name": "nama keluarga",
    "guest_last_name_kanji": "",
    "header": "Buat Akun",
    "hiragana_error": "",
    "kanji_katakana_error": "Silakan masukkan Kanji, Hiragana, Katakana atau Bahasa Inggris",
    "kanji_error": "",
    "last_name": "nama keluarga",
    "lastNameLocales": {
      "ja": "nama keluarga",
      "zh_Hant": "nama keluarga",
      "ko": "nama keluarga"
    },
    "last_name_kanji": "",
    "last_name_kanji_placeholder": "",
    "last_name_placeholder": "Smith",
    "lastNamePlaceHolderLocales": {
      "ja": "Smith",
      "zh_Hant": "Smith",
      "ko": "Smith"
    },
    "lastName": "Nama keluarga",
    "lastNameError": "Harap konfirmasi nama belakang Anda",
    "lastNameKana": "Nama keluarga",
    "membershipId": "Nomor keanggotaan",
    "membershipProgram": "Program keanggotaan",
    "name": "Nama",
    "nameJaPlaceholder": "Silakan masukkan Kanji, Hiragana, Katakana atau Bahasa Inggris.",
    "nameJaValidation": "Silakan masukkan Kanji, Hiragana, Katakana atau Bahasa Inggris.",
    "nameInvalidCharMessage": "Masukan yang Anda berikan mengandung karakter yang tidak didukung oleh sistem kami. ",
    "nameKanaJaPlaceholder": "Silakan masukkan Hiragana.",
    "namePlaceholder": {
      "en": "Silakan masukkan bahasa Inggris.",
      "ja": "Silakan masukan Hiragana atau Bahasa Inggris.",
      "zh_Hant": "Silakan masukkan Kanji atau Bahasa Inggris.",
      "zh_Hans": "Silakan masukkan Kanji atau Bahasa Inggris.",
      "ko": "Silakan masukan Hangul atau Bahasa Inggris."
    },
    "nameValidation": {
      "ja": "Silakan masukan Hiragana atau Bahasa Inggris.",
      "zh_Hant": "Silakan masukkan Kanji atau Bahasa Inggris.",
      "zh_Hans": "Silakan masukkan Kanji atau Bahasa Inggris.",
      "ko": "Silakan masukan Hangul atau Bahasa Inggris."
    },
    "nationality": "Kebangsaan",
    "newPassword": "Kata sandi baru (minimal 8 karakter dengan huruf besar dan kecil serta angka.)",
    "noAccount": "Anda belum memiliki akun?",
    "occupation": "Pekerjaan",
    "or": "Atau",
    "orContinueBelow": "Atau lanjutkan di bawah",
    "password": "Kata sandi",
    "passwordConfirmationError": "Kata sandi Anda dan konfirmasinya tidak cocok.",
    "passwordError": "Harap konfirmasi kata sandi Anda",
    "passwordSignUp": "Kata sandi (minimal 8 karakter dengan huruf besar dan kecil dan angka.)",
    "passwordNote": "minimal 8 karakter dengan huruf besar dan kecil dan angka.",
    "pleaseSelect": "Pilih program",
    "postCode": "Kode Pos",
    "postCodeError": "Hanya huruf dan angka yang diperbolehkan.",
    "prefecture": "Prefektur",
    "notApplicable": "Tak dapat diterapkan",
    "privacyPolicy": "Kebijakan pribadi",
    "promotionsCheckbox": "Saya ingin menerima penawaran dan promosi khusus melalui email",
    "resend": "Kirim ulang",
    "resendConfirmationEmail": "Tidak menerima email konfirmasi?",
    "resendConfirmationEmailError": "Email ini belum pernah didaftarkan sebelumnya.",
    "resendConfirmationEmailTitle": "Kirim ulang email konfirmasi",
    "receiptName": "Nama Penerimaan",
    "receiptUnicode": "",
    "send": "Mengirim",
    "setPassword": "Tetapkan Kata Sandi",
    "setup": "Buat Akun",
    "setupResultSecondary01": "Jika Anda ingin mencoba kembali mendaftar pendaftaran keanggotaan. Silakan coba lagi dari {0}",
    "setupResultSecondary02": "Kami telah mengirimkan email untuk mengkonfirmasi alamat email yang Anda daftarkan.\nKarena ini adalah pendaftaran sementara, silakan akses URL di email untuk menyelesaikan pendaftaran keanggotaan.\nAnda dapat masuk setelah Anda menyelesaikan pendaftaran.",
    "signIn": "Masuk",
    "signInHere": "masuk di sini",
    "signInTo": "Masuk ke {0}",
    "signUp": "Buat sebuah akun",
    "signUpResult": "Konfirmasikan alamat email Anda",
    "signUpResult02": "Pendaftaran berhasil",
    "signUpResult02Secondary": "Terima kasih telah mendaftar ke {n}.",
    "signUpResult02Secondary02": "Email konfirmasi telah dikirim ke alamat email yang Anda berikan.",
    "signUpResultSecondary": "Terima kasih telah mendaftar ke {n}.",
    "signUpResultSecondary02": "Untuk menggunakan akun Anda, Anda perlu mengonfirmasi alamat email yang Anda berikan.",
    "signUpResultSecondary03": "Silakan periksa kontennya dan klik URL berikut.",
    "signUpResultSecondary04": "Pendaftaran keanggotaan akan selesai setelah mengkonfirmasi alamat email Anda.",
    "signUpSecondary": "Buat akun Anda, hanya membutuhkan waktu kurang dari satu menit.",
    "term": "Dengan mendaftar, Anda menyetujui {0} dan {1}.",
    "termError": "Mohon terima syarat dan ketentuannya",
    "termOfUse": "syarat Penggunaan",
    "wifi": "Wifi gratis di kamar",
    "yetReceived": "Tidak menerima emailnya? {0}",
    "resendEmail": "Kirim Ulang Email",
    "pleaseResetPassword": "Pendaftaran akun email Anda telah dikonfirmasi, perlu diperhatikan bahwa tidak perlu mengirim ulang email konfirmasi.\nJika Anda mengetahui sandi Anda, silakan langsung {0}.\nAlternatifnya, jika Anda lupa sandi, gunakan opsi {1}.",
    "pleaseResetPasswordLink1": "Gabung",
    "pleaseResetPasswordLink2": "pengaturan ulang kata sandi",
    "receiptNameFormatError": "",
    "receiptUnicodeFormatError": "",
    "receiptNameRequiredError": "",
    "receiptUnicodeRequiredError": ""
  },
  "membersihp": {
    "target": "Keanggotaan sasaran"
  },
  "multipleCurrency": {
    "JPY": "Yen jepang",
    "ARS": "Peso Argentina",
    "AUD": "Dollar Australia",
    "BRL": "Real Brasil",
    "CAD": "Dolar Kanada",
    "CNY": "Yuan Tiongkok",
    "CZK": "Koruna Ceko",
    "DKK": "Krone Denmark",
    "EUR": "Euro",
    "HKD": "Dollar Hongkong",
    "INR": "Rupee India",
    "IDR": "Rupiah Indonesia",
    "ILS": "Syikal Baru Israel",
    "MOP": "Makau Pacata",
    "MYR": "Ringgit Malaysia",
    "MXN": "Peso Meksiko",
    "TWD": "Dolar Taiwan Baru",
    "NZD": "Dolar Selandia Baru",
    "NOK": "Krone Norwegia",
    "PHP": "Peso Filipina",
    "GBP": "Pound sterling",
    "QAR": "Riyal Qatar",
    "RUB": "Rubel Rusia",
    "SAR": "Riyal Arab Saudi",
    "SGD": "Dolar Singapura",
    "ZAR": "Rand Afrika Selatan",
    "KRW": "Won Korea Selatan",
    "LKR": "Rupee Sri Lanka",
    "SEK": "Krona Swedia",
    "CHF": "Franc Swiss",
    "THB": "Baht Thailand",
    "AED": "Dirham Uni Emirat Arab",
    "USD": "Dolar Amerika Serikat",
    "VND": "Dong Vietnam",
    "modalTitle": "Pilih mata uang Anda",
    "modalDescription": "Silakan pilih mata uang. Jika Anda memilih mata uang selain yen Jepang, metode pembayaran Anda akan dibatasi pada kartu kredit.",
    "suggestedCurrencies": "Mata uang yang umum digunakan",
    "allCurrencies": "Semua mata uang",
    "creditCardWarning": "Mata uang telah berubah karena perubahan metode pembayaran. Silakan periksa jumlahnya.",
    "exchangeRateUpdatedError": "Nilai tukar telah diperbarui, jadi periksa harga terbaru dan lakukan reservasi.",
    "currency": "Mata uang"
  },
  "myAccount": {
    "availablePoints": "Tersedia {0}",
    "availablePointsDesc": "Jumlah total {0} yang saat ini Anda simpan dan dapat dibelanjakan.",
    "totalRankingPoints": "Anda memiliki peringkat {amount} {pointName}.",
    "changeLoginEmail": "Ubah email masuk",
    "changeLoginEmailDescription": "Untuk memperbarui email Anda, silakan ubah di bawah. Anda akan dikirimi email verifikasi. Jika Anda mengalami masalah dalam menerima email, Anda dapat mengubah alamat email dan mencoba lagi sebanyak yang Anda suka.",
    "changeLoginEmailPendingText": "Alamat email Anda telah diperbarui dan menunggu verifikasi",
    "changePassword": "Setel ulang kata sandi",
    "confirmLoginEmail": "Menunggu email baru",
    "edit": "Sunting",
    "emailLabel": "Surel",
    "loginEmail": "Email Masuk",
    "loginInfo": "Informasi Masuk",
    "notRegistered": "Tidak terdaftar",
    "pageTitle": "Akun",
    "password": "Kata sandi",
    "passwordLabel": "Kata sandi",
    "paymentInfo": "Informasi Pembayaran",
    "paymentOptions": "Opsi pembayaran",
    "paymentOptionsDesc": "Berikut adalah daftar kartu kredit Anda yang terdaftar. Segala informasi terkait kartu kredit akan dikelola dengan kerahasiaan yang ketat.",
    "pendingEmailText": "{n} sedang menunggu verifikasi",
    "personalInfo": "Informasi pribadi",
    "promotions": "Kupon",
    "point": "Poin",
    "removeAccount": "Menghapus akun",
    "removeAccountConfirmationText": "Anda tidak dapat memulihkan akun Anda setelah dihapus. Apa kamu yakin?",
    "removeAccountReason01": "Tidak digunakan lagi",
    "removeAccountReason02": "Biaya kamar mahal",
    "removeAccountReason03": "Manfaat keanggotaan tidak menarik",
    "removeAccountReasonInput": "Ketikkan alasan Anda",
    "removeAccountReasonOthers": "Lainnya (Silahkan ketikkan alasan Anda)",
    "removeAccountSecondaryText": "Apakah Anda ingin menghapus akun Anda?",
    "removeAccountSecondaryText02": "Harap diperhatikan bahwa ini tidak dapat dibalik",
    "removeCreditCard": "Hapus Kartu Kredit",
    "removeCreditCardConfirm": "Apakah Anda yakin ingin menghapus kartu kredit ini?",
    "resendEmail": "Kirim ulang verifikasi",
    "resendEmailSuccess": "Email telah dikirim ke kotak masuk Anda.",
    "yourCreditCards": "Kartu kredit Anda",
    "membershipId": "ID keanggotaan",
    "myQRCode": "Kode QR saya",
    "promotion": "Pengaturan email promosi",
    "historyStatus": {
      "expired": "Poin Kedaluwarsa",
      "future": "Poin Masa Depan",
      "spent": "Poin yang Dibelanjakan",
      "gained": "Poin yang Diperoleh",
      "refunded": "Poin Pengembalian Dana"
    },
    "value": "Nilai",
    "badCards": "Karena perubahan sistem, kartu ini tidak dapat digunakan untuk reservasi baru.",
    "unlockRank": "Buka peringkat {rank} dengan mengumpulkan {points} {pointName} sebelum tanggal {date}.",
    "unlockRankNoDate": "Buka peringkat {rank} dengan mengumpulkan {points} {pointName}.",
    "earnMorePointsToKeepRank": "Dapatkan {points} lebih banyak {pointName} sebelum tanggal {date} untuk mempertahankan peringkat {rank}.",
    "achievedHighest": "Selamat! Anda telah mencapai peringkat tertinggi.",
    "achievedHighestOnDate": "Selamat! Anda telah memenuhi syarat dan akan mencapai peringkat tertinggi pada {date}."
  },
  "myProfile": {
    "cancel": "Batalkan pemesanan Anda",
    "cancelled": "DIBATALKAN",
    "change": "Mengubah",
    "checkIn": "Mendaftar",
    "checkOut": "Periksa",
    "confirmationNo": "Nomor pendaftaran",
    "multiRoom": "MULTI KAMAR",
    "myReservations": "Reservasi",
    "myPage": "Halamanku",
    "noResultButtonText": "Mulailah merencanakan perjalanan Anda",
    "noResultText": "Anda belum memesan apa pun.",
    "pageTitle": "Akun Anda",
    "viewDetails": "Melihat rincian",
    "viewFlightDetails": "Detail pemesanan (termasuk tiket pesawat)",
    "myVoucher": {
      "amount": "Jumlah",
      "askConsume": "Konsumsi kupon?",
      "askConsumeMessage": "Kupon ini hanya dapat digunakan satu kali saja.",
      "cancel": "Membatalkan",
      "consume": "Mengkonsumsi",
      "consumeVoucher": "Konsumsi Kupon",
      "excludeDuring": "Kecualikan selama",
      "makeReservation": "Buat Reservasi",
      "targetStayingPeriod": "Target masa tinggal",
      "validThrough": "Berlaku sampai",
      "name": "Nama",
      "consumptionTime": "Waktu konsumsi",
      "reservationNumber": "Nomor pendaftaran",
      "status": {
        "status": "Status",
        "available": "Tersedia",
        "pending": "Tertunda",
        "expired": "Kedaluwarsa",
        "consumed": "Dikonsumsi"
      },
      "selectHotel": "Pilih Hotel"
    },
    "lottery": {
      "status": {
        "registered": "Sedang berlangsung",
        "not_winner": "Tidak terpilih",
        "winner_reservation_confirmed": "Dipilih (Dipesan)",
        "winner_reservation_cancelled": "Reservasi Dibatalkan",
        "registration_cancelled": "Entri Dibatalkan",
        "winner_reservation_pending_declined": "Entri Dibatalkan",
        "winner_reservation_pending": "Dipilih (Konfirmasi Menunggu)"
      },
      "text": "Lotre"
    },
    "processBooking": "Proses Pemesanan",
    "cancelEntry": "Batalkan Entri",
    "lotteryEntryNo": "Nomor entri lotere"
  },
  "mcp": {
    "chooseCurrency": "Pilih mata uang yang Anda inginkan",
    "recommendLocalCurrency": "Kami merekomendasikan untuk membayar dalam mata uang lokal Anda guna meminimalkan fluktuasi nilai tukar dan risiko pembatalan. Silakan pilih VISA atau Mastercard, karena pembayaran dalam mata uang asing saat ini tidak didukung untuk kartu kredit yang dipilih."
  },
  "organization": {
    "enterDetail": "Silakan masukkan ID akun dan kata sandi Anda.",
    "signIn": "Masuk perusahaan"
  },
  "ota": {
    "changeLoginEmailPendingText": "Alamat email Anda telah diperbarui dan menunggu verifikasi",
    "name": {
      "agoda": "www.agoda.com",
      "booking_com": "Pemesanan com",
      "expedia": "Expedia",
      "jalan": "Jalan",
      "rakuten_travel": "Perjalanan Rakuten",
      "rakutenTravel": "Perjalanan Rakuten"
    },
    "priceNotAvailable": "Tidak tersedia",
    "loadingTitle0": "Menemukan Masa Tinggal Sempurna Anda",
    "loadingDescription0": "Memanfaatkan AI untuk saran hotel yang dipersonalisasi. Masa tinggal impian Anda sedang dimuat...",
    "loadingTitle1": "Tingkatkan Pengalaman Anda di Jepang",
    "loadingDescription1": "Benamkan diri Anda dalam kekayaan budaya Jepang dengan menikmati upacara minum teh tradisional. Temukan seni persiapan matcha dan nikmati ketenangannya.",
    "loadingTitle2": "Permata Tersembunyi Menunggu",
    "loadingDescription2": "Jelajahi keajaiban terpencil dengan bertualang ke lingkungan yang kurang dikenal. Temukan kekayaan lokal dan nikmati pesona otentik kota ini.",
    "loadingTitle3": "Terhubung dengan Tradisi",
    "loadingDescription3": "Benamkan diri Anda dalam keajaiban distrik geisha. Saksikan pertunjukan tari tradisional atau ikuti upacara minum teh untuk melihat budaya autentik.",
    "loadingTitle4": "Kenikmatan Kuliner",
    "loadingDescription4": "Selami beragam dunia masakan Jepang dengan mengunjungi berbagai pasar jajanan kaki lima yang terkenal. Dari sushi gurih hingga takoyaki lezat, puaskan selera Anda.",
    "loadingTitle5": "Ketenangan di Alam",
    "loadingDescription5": "Hilangkan hiruk pikuk perkotaan dengan berjalan-jalan menyusuri hutan bambu yang mempesona. Rasakan ketenangan dan keindahan abadi kota bersejarah ini.",
    "loadingTitle6": "Tontonan Bunga Sakura",
    "loadingDescription6": "Rencanakan kunjungan Anda selama musim bunga sakura untuk menyaksikan pemandangan ikonis Jepang yang bermandikan warna merah jambu. Bergabunglah dengan penduduk setempat dalam perayaan hanami untuk mendapatkan pengalaman yang benar-benar ajaib.",
    "fromBwToOtaPaymentError": "Ada yang salah. Silakan tekan tombol 'Berikutnya' atau pilih metode pembayaran lain untuk mencoba lagi.",
    "fromBwToOtaRestoreDataError": "Ada yang salah. Silakan masukkan kembali data Anda dan tekan tombol 'Berikutnya' lagi.",
    "searchHotels": "Cari hotel",
    "aiRecommendations": "Rekomendasi AI",
    "recommendationsForU": "Rekomendasi untuk Anda",
    "interestedIn": "Temukan Rekomendasi Alternatif",
    "home": "Rumah",
    "showingCountAvailable": "Menampilkan {count} tersedia dari {total} properti",
    "standardTravelAgency": "Kondisi Bisnis",
    "termsOfUse": "Ketentuan Penggunaan",
    "privacyPolicy": "Kebijakan pribadi",
    "companyProfile": "Profil Perusahaan",
    "bookingSupport": "Dukungan Pemesanan",
    "search": "Cari hotel",
    "checkAvailability": "Cek ketersediaan",
    "avgPricePerNight": "Harga referensi per malam",
    "adults": "{n} dewasa | {n} dewasa | {n} dewasa",
    "GDPR": "Kebijakan Privasi GDPR",
    "homepage": {
      "recommend1": "Temukan saya hotel ramah anggaran di Tokyo untuk menginap di akhir pekan.",
      "recommend2": "Tolong bantu saya menemukan hotel resor di Okinawa untuk tiga orang.",
      "recommend3": "Jelajahi Ryokan di Kyoto untuk ulang tahun istri saya.",
      "recommend4": "Bulan depan, saya akan melakukan perjalanan bisnis ke Kobe. Apakah ada hotel yang direkomendasikan untuk akomodasi solo?",
      "placeholder": "Misalnya. Saya ingin memesan kamar untuk dua orang selama tiga malam di hotel dekat Shinjuku."
    },
    "bookingConfirmation": "Konfirmasi pemesanan",
    "confirmToOtaTitle": "Proses Konfirmasi Reservasi",
    "confirmToOtaDescription": "Anda akan diarahkan ke situs biro perjalanan ({0}). Reservasi Anda akan selesai dengan menyelesaikan pembayaran di muka di halaman berikutnya.",
    "confirmToOtaDescriptionInGuestForm": "Anda akan diarahkan ke situs biro perjalanan ({0}). Reservasi Anda akan selesai dengan menyelesaikan pembayaran di muka di halaman berikutnya.",
    "confirmToOtaDescriptionInGuestFormOnsiteCC": "Anda akan diarahkan ke situs biro perjalanan ({0}). Reservasi Anda akan selesai dengan mendaftarkan kartu kredit di halaman berikutnya.",
    "aiSearchNoResult": "AI kami sedang dalam proses pembelajaran dan tidak dapat menghasilkan rekomendasi yang relevan berdasarkan masukan Anda. Harap berikan rincian lebih lanjut, seperti lokasi, jumlah orang, deskripsi fasilitas, dll. Selain itu, kami memiliki rekomendasi berikut yang mungkin menarik bagi Anda."
  },
  "otaRoomsResult": {
    "noRoomsAvailable": "Sayangnya, tidak ada kamar tersedia yang memenuhi kebutuhan Anda.",
    "noRoomsAvailableDesc": "Silakan pilih tanggal lain, atau ubah jumlah tamu untuk melihat apakah kami memiliki sesuatu yang tersedia untuk Anda.",
    "location": "Lokasi",
    "showAllPhotos": "Tampilkan semua foto"
  },
  "otaRoomsMap": {
    "title": "Peta",
    "seeMap": "Lihat Peta"
  },
  "paymentMethod": {
    "paymentKRWCreditCardFooterTitle": "Online payment is processed by:",
    "paymentKRWCreditCardFooterLine1": "tripla Co., Ltd. (Korea branch)",
    "paymentKRWCreditCardFooterLine1CEO": "CEO: Kazuhisa Takahashi",
    "paymentKRWCreditCardFooterLine2": "Business registration number: 538-84-00072",
    "paymentKRWCreditCardFooterLine3": "3F Concordian Building, 76 Saemunan-ro, Jung-gu, Seoul, South Korea (P. 03185)",
    "paymentKRWCreditCardFooterLine4": "support_ko{'@'}tripla.io",
    "paymentKRWCreditCardFooterLine4Link": "mailto:support_ko{'@'}tripla.io",
    "paypalCheckbox1": "Harap pastikan untuk menggunakan kartu kredit orang yang membuat reservasi. Jika nama pemegang kartu tidak sesuai dengan nama reservasi, reservasi dapat dibatalkan tanpa pemberitahuan.",
    "paypalCheckbox2": "Tidak dapat digunakan jika penerbit kartu kredit tidak mendukung 3D Secure.",
    "paypalCheckbox3": "Informasi pribadi berikut yang dikumpulkan oleh perusahaan kami akan diberikan kepada penerbit kartu yang Anda gunakan untuk mendeteksi dan mencegah penggunaan tidak sah. Nama, nomor telepon, alamat email, lingkungan internet, dll.",
    "paypalCheckbox4": "Jika penerbit kartu yang Anda gunakan berada di luar negeri, informasi ini dapat ditransfer ke negara tempat penerbit tersebut berada.",
    "paypalCheckbox5": "Anak di bawah umur diperbolehkan menggunakan layanan ini dengan persetujuan orang tua atau walinya.",
    "pre_paid": "Pembayaran kartu kredit",
    "credit_card": "Prabayar",
    "payments": {
      "credit_card": "Pembayaran kartu kredit"
    },
    "deferred_payment": "Pembayaran yang ditangguhkan",
    "on_site": "Di tempat",
    "on_site_credit_card_required": "Di tempat",
    "type": "Tipe pembayaran",
    "twd_credit_card": "Pembayaran penuh dengan kartu kredit",
    "twd_credit_card_deposit": "Setoran dengan kartu kredit",
    "bank_transfer": "transfer Bank",
    "paymentPromotion": "Promosi pembayaran",
    "paymentPromotionTooltip": "Promosi dapat diubah atau ditutup tanpa peringatan.",
    "undefined": ""
  },
  "pointHistory": {
    "availableAt": "Tersedia di",
    "cancellation": "Pembatalan",
    "dateColumn": "Tanggal",
    "descriptionColumn": "Keterangan",
    "failToLoadErrorMessage": "Saya minta maaf. Kami gagal memuat riwayat poin Anda sekarang.",
    "noData": "Anda tidak memiliki riwayat poin apa pun saat ini.",
    "pointExpirationColumn": "Kedaluwarsa Poin",
    "pointHistory": "Sejarah Poin",
    "pointsColumn": "Jumlah Poin",
    "pointUse": "Penggunaan titik",
    "stay": "Tinggal",
    "viewMore": "Lihat selengkapnya",
    "exchangeDigico": "{titik} Pertukaran",
    "exchangeDigicoDesc": "Silakan masukkan jumlah {point} yang ingin Anda tukarkan ke poin Digico. Poin Digico dapat digunakan untuk menukarkan {point} Anda ke program poin lain termasuk poin Amazon, Apple, Google. Proses ini tidak dapat dibatalkan dan poin Digico hanya berlaku selama 180 hari setelah penukaran. Layanan ini hanya dapat diakses di Jepang. Fasilitas tidak dapat menjawab pertanyaan apapun mengenai poin Digico setelah ditukarkan. Untuk informasi lebih lanjut tentang poin Digico, silakan lihat {link}.",
    "exchangeDigicoLink": "Di Sini",
    "exchangeDigicoLog": "Pertukaran Poin Digico",
    "exchangeAvaliablePoints": "Total {point} Tersedia",
    "exchangeBtn": "Menukarkan",
    "exchangeHistory": "{titik} Pertukaran",
    "exchangedAmount": "Jumlah pertukaran",
    "exchangedDigicoCode": "Kode",
    "exchangeConfirm": "Proses ini tidak dapat dibatalkan. Apakah Anda yakin ingin mengonfirmasi penukaran {point} Anda?",
    "exchangeConfirmBtn": "Melanjutkan",
    "digicoPointUrl": "URL Penukaran Poin Digico:",
    "invalidAmount": "Silakan masukkan jumlah poin yang valid.",
    "totalBalance": "Total",
    "currentPoints": "Poin yang Tersedia",
    "futurePoints": "Poin Masa Depan"
  },
  "pointSpending": {
    "balance": "Saldo poin yang tersedia",
    "errors": {
      "incorrectAmount": "Anda dapat menggunakan poin dengan kelipatan {n}.",
      "moreThanPrice": "Anda tidak dapat menggunakan poin lebih dari total harga.",
      "upToBalance": "Anda tidak dapat menggunakan poin lebih dari saldo Anda.",
      "pointUsageError": "Tidak diperbolehkan melanjutkan pembayaran kartu kredit bila total pembayaran Anda 0.\nUntuk mengaktifkan pembayaran online, Anda dapat mengurangi poin pembelanjaan pada langkah sebelumnya."
    },
    "minimumAmount": "Jumlah poin pembelanjaan minimum harus {0} poin atau lebih.",
    "points": "Poin",
    "saveForLater": "Simpan poin saya untuk nanti",
    "updateRule": "Anda tidak dapat menggunakan poin yang digunakan untuk reservasi awal. Kami akan mengembalikan poin untuk reservasi awal setelah reservasi diperbarui.",
    "useAll": "Tukarkan seluruh saldo poin saya",
    "useAmount": "Tebus sejumlah tertentu",
    "title": "Poin"
  },
  "points": {
    "naming": {
      "default": "Titik",
      "history": "{titik} Sejarah",
      "available": "Tersedia {point}",
      "future": "Masa depan {titik}",
      "expired": "Kedaluwarsa {point}",
      "gained": "Memperoleh {point}",
      "spent": "Menghabiskan {point}",
      "save": "Simpan {point} saya untuk nanti",
      "redeem": "Tukarkan seluruh saldo {point} saya",
      "redeem2": "Tukarkan jumlah {point} maksimum",
      "refunded": "Pengembalian dana {point}",
      "miniSpending": "Jumlah pembelanjaan minimum sebesar {point} harus {number} atau lebih.",
      "useIncrements": "Anda dapat menggunakan {point} dengan kelipatan {number}.",
      "discount": "diskon {point}",
      "earn": "Dapatkan {number} {point} dengan memesan kamar ini",
      "checkOut": "{point} akan ditambahkan 3 hari setelah tanggal check-out.",
      "recalculateOnCheckoutDate": "Jumlah {point} yang ditampilkan adalah perkiraan. Nilai pastinya akan dihitung ulang pada tanggal checkout.",
      "usePoint": "dengan {titik}",
      "empty": "Anda tidak memiliki riwayat {point} apa pun saat ini.",
      "total": "Total",
      "availableBalance": "Saldo {point} tersedia",
      "redeemRemark": "Tebus sejumlah tertentu",
      "cannotUse": "Anda tidak dapat menggunakan {point} yang digunakan untuk reservasi awal. Kami akan mengembalikan dana sebesar {point} untuk reservasi awal setelah reservasi diperbarui."
    },
    "getRegisterPoint": "Dapatkan {amount} {points_name} untuk mendaftar sebagai anggota"
  },
  "progressBar": {
    "completeLottery": "Entri Lotere Lengkap",
    "completeLotterySubtitle": "Anda telah menyelesaikan entri lotere",
    "confirmationSubtitle": "Kamar Anda sudah dipesan",
    "confirmationSubtitleOTA": "Kamar Anda sudah dipesan",
    "confirmationTitle": "Selesaikan pemesanan",
    "confirmationTitleOTA": "Konfirmasi",
    "guestSubtitle": "Isi detail Anda",
    "guestTitle": "Detail Tamu",
    "paymentSubtitle": "Pilih jenis pembayaran",
    "paymentTitle": "Pembayaran"
  },
  "reservation": {
    "cancelled": "Dibatalkan",
    "completed": "Lengkap",
    "upcoming": "Mendatang",
    "lottery": "Lotre",
    "filtersLabel": "Saring",
    "filters": {
      "all": "Semua",
      "onSiteChecked": "Pembayaran di Tempat",
      "on_site_credit_card_required": "Pembayaran di Tempat",
      "otaPrePaidChecked": "Pembayaran Kartu Kredit paling lambat tanggal {0}",
      "bookingWidgetPrePaidChecked": "Pembayaran online dengan kartu kredit",
      "deferredPaymentChecked": "Pembayaran yang ditangguhkan"
    },
    "reservedChannel": {
      "all": "Semua",
      "title": "Saluran Cadangan",
      "officialWebsite": "Situs Web Resmi (Pembayaran di Tempat）",
      "wbf": "Paket Dinamis",
      "tripla": "Situs web pemesanan perjalanan (pembayaran kartu kredit)"
    },
    "signInUpReminder": "Masuk / naik untuk Menerima Manfaat Keanggotaan"
  },
  "reservationAction": {
    "contactTriplaSupport": "Hubungi dukungan tripla",
    "applyGOTO": "Terapkan kode kupon",
    "bookingCancel": "Batalkan pemesanan Anda",
    "bookingUpdate": "Perbarui detail tamu",
    "corporateSignInRequired": "Diperlukan login perusahaan untuk memperbarui rencana perjalanan Anda.",
    "differentFacilityWarning": "Untuk memperbarui reservasi Anda, silakan login melalui fasilitas yang Anda pesan.",
    "everythingCorrectSubtitle": "Anda dapat memperbarui informasi Anda atau membatalkan reservasi ini di bawah.",
    "everythingCorrectTitle": "Kelola reservasi",
    "invoiceDownload": "Detail Cetak",
    "madeMistakeHelp": "Kami berharap dapat segera melayani Anda lagi.",
    "madeMistakeTitle": "Pemesanan Anda telah dibatalkan.",
    "memberSignInRequired": "Login keanggotaan diperlukan untuk memperbarui reservasi.",
    "pleaseCancelAll": "​Jika Anda ingin membatalkan semua kamar, harap batalkan reservasi.",
    "payByCreditCard": "Bayar dengan kartu kredit",
    "reductionOfNights": "Pengurangan malam",
    "reductionOfRooms": "Pengurangan ruangan",
    "selectRoomsToCancel": "Silakan pilih kamar yang ingin Anda batalkan.",
    "signOutRequired": "Silakan keluar untuk memperbarui reservasi.",
    "updateExpired": "Reservasi ini tidak dapat diperbarui lagi karena telah melewati batas waktu.",
    "updateItinerary": "Perbarui rencana perjalanan Anda",
    "differentAccountWarning": "Silakan masuk dengan akun yang benar untuk memperbarui reservasi",
    "differentOrgWarning": "Silakan masuk dengan perusahaan yang benar untuk memperbarui reservasi",
    "coupon": {
      "signOutRequired": "Silakan keluar untuk menerapkan kupon.",
      "memberSignInRequired": "Masuk keanggotaan diperlukan untuk menerapkan kupon.",
      "corporateSignInRequired": "Diperlukan login perusahaan untuk menerapkan kupon.",
      "differentFacilityWarning": "Untuk menggunakan kupon, silakan masuk melalui fasilitas yang Anda pesan.",
      "differentAccountWarning": "Silakan masuk dengan akun yang benar untuk menerapkan kupon.",
      "differentOrgWarning": "Silakan masuk dengan perusahaan yang benar untuk menerapkan kupon."
    },
    "notEnoughStays": "Durasi menginap yang dipilih harus sama dengan atau lebih besar dari durasi menginap minimum pada reservasi ini ({0} malam) .\n",
    "viewMyReservations": "Lihat Reservasi Saya",
    "madeMistakeHelp2": "Kami berharap dapat segera melayani Anda lagi",
    "madeMistakeTitle2": "Pemesanan Dibatalkan"
  },
  "reservationCancellation": {
    "roomN": "Kamar {0}",
    "cancellationFees": "Biaya pembatalan",
    "cancellationConfirm2": "Apakah Anda yakin untuk membatalkan reservasi Anda?",
    "reason2": "Mohon sampaikan alasan pembatalan Anda",
    "reasonTripCancelled2": "Perubahan rencana perjalanan",
    "reasonRoomCheaper2": "Menemukan opsi yang lebih terjangkau di tempat lain",
    "reasonNbTrvellers2": "Jumlah pemudik disesuaikan",
    "reasonDifferentHotel2": "Memilih hotel alternatif",
    "reasonOther2": "Lainnya (sebutkan)",
    "cancel": "Batalkan Reservasi",
    "cancellationConfirm": "Apakah Anda yakin ingin membatalkan reservasi Anda?",
    "cancellationFee": "Biaya pembatalan",
    "cancellationOnDay": "Pembatalan pada hari tersebut tidak dapat dilakukan secara online.",
    "cancellationPolicy": "Kebijakan Pembatalan",
    "cancellationTitle": "Batalkan pemesanan Anda",
    "contactByChatPhone": "Silakan hubungi kami melalui telepon.",
    "contactByPhone": "Silakan hubungi kami melalui telepon.",
    "contactPhone": "Nomor telepon {0}",
    "keep": "Simpan Reservasi",
    "nonRefundable": "Tidak bisa dikembalikan",
    "reason": "Harap berikan alasan Anda membatalkan",
    "reasonDifferentHotel": "Saya menemukan hotel lain",
    "reasonNbTrvellers": "Jumlah pemudik berubah",
    "reasonOther": "Lainnya",
    "reasonOtherHelp": "Jika lainnya, harap berikan detailnya kepada kami",
    "reasonRoomCheaper": "Kamarnya lebih murah di tempat lain",
    "reasonTripCancelled": "Perjalananku dibatalkan",
    "roomNbCancelFee": "Biaya pembatalan kamar {0}",
    "totalCancelFee": "Total biaya pembatalan",
    "changePlanQuestion": "Perlu mengubah rencana Anda?",
    "changePlanDesc": "Alih-alih membatalkan, Anda dapat memperbarui tanggal reservasi Anda untuk lebih cocok dengan jadwal Anda. Lakukan penyesuaian fleksibel untuk memastikan masa tinggal Anda sesuai dengan keinginan Anda.",
    "changePlanWarnTL": "Rencana dari reservasi asli tidak dapat diubah.",
    "cancelTitle": "Batalkan reservasi Anda",
    "cancellationNote": "Harap diperhatikan bahwa biaya pembatalan mungkin berlaku.",
    "cancellationReason": "Alasan Pembatalan:",
    "cancellationTellUs": "Tolong beri tahu kami alasan Anda membatalkan:",
    "reasonPlanChange": "Perubahan rencana perjalanan",
    "reasonAffordableOption": "Menemukan opsi yang lebih terjangkau di tempat lain",
    "reasonNumberAdjustment": "Menyesuaikan jumlah pelancong",
    "reasonHotelAlt": "Memilih hotel alternatif",
    "reasonOthers": "(silakan spesifikasikan)"
  },
  "reservationConfirmation": {
    "bookingConfirmed": "Pemesanan Anda telah dikonfirmasi",
    "cancelledSuccessfully": "Pemesanan Berhasil Dibatalkan",
    "totalStays": "Total lama menginap",
    "contactProperty": "Hubungi properti",
    "yourRoomDetails": "Detail kamar Anda",
    "phone": "Telepon",
    "email": "Surel",
    "website": "Situs web",
    "cancellationFreeUntil": "Pembatalan gratis hingga {0}",
    "cannotChangePlan": "Anda tidak dapat mengubah rencana reservasi awal.",
    "confirmationEmail": "Kami telah mengirimkan email konfirmasi ke {0}",
    "extra": {
      "answer": "Menjawab",
      "dateTime": "Tanggal Waktu",
      "extrasPrice": "Ekstra",
      "items": "Barang",
      "notes": "Catatan",
      "price": "Harga",
      "priceIncludeTax": "Harga (termasuk pajak)",
      "question": "Pertanyaan",
      "roomPrice": "Total harga kamar"
    },
    "extrasSubtotal": "Subtotal tambahan",
    "nonRefundableReminder": "Pemesanan ini tidak dapat dikembalikan. Harap dicatat bahwa tidak ada pengembalian dana yang akan dilakukan atas pembatalan Anda.",
    "originalReservationPlans": "Rencana reservasi awal",
    "reservationDetail": "Detil Reservasi",
    "selectNewItinerary": "Silakan pilih rencana perjalanan baru Anda dan jumlah orang.",
    "updateBooking": "Perbarui pemesanan Anda",
    "cannotUpdateTooltip": "Karena konten atau harga reservasi telah diubah oleh staf fasilitas kami sebelumnya sesuai dengan perjanjian Anda, Anda tidak dapat memperbarui/membatalkan reservasi."
  },
  "reservationDetail": {
    "bookingDetails": "Detail Pemesanan",
    "adult": "{n} Dewasa | {n} Dewasa | {n} Dewasa",
    "accommodationTax": "Pajak akomodasi",
    "checkIn": "Tanggal check-in",
    "checkOut": "Tanggal keluar",
    "child": "{n} Anak | {n} Anak | {n} Anak-anak",
    "dayUse": "Hari penggunaan",
    "details": "",
    "detailsTitle": "Detail Reservasi",
    "discount": "Diskon",
    "discounts": "Diskon",
    "discountedPrice": "Diskon Poin",
    "guest": "{n} Tamu | {n} Tamu | {n} Tamu",
    "guests": "Tamu",
    "hotel": "Hotel",
    "maxGuests": "Tamu Maks",
    "night": "{n} Malam | {n} Malam | {n} Malam",
    "nights": "malam",
    "numberOfGuest": "Kamar",
    "numberOfNights": "malam",
    "numberOfPeople": "Jumlah Orang",
    "numberOfAdults": "{n} Dewasa | {n} Dewasa | {n} Dewasa",
    "numberOfChildren": "{n} Anak | {n} Anak | {n} Anak-anak",
    "originalPrice": "Jumlah total pembayaran",
    "pointDiscount": "Diskon poin",
    "pointInstruction": "Poin akan ditambahkan 3 hari setelah tanggal check-out.",
    "pointReward": "Dapatkan {0} poin dengan memesan kamar ini",
    "priceBreakDown": "Detail harga",
    "priceDetails": "Detail Harga",
    "reservationDetails": "Detail Reservasi",
    "reservationNumber": "Nomor pendaftaran",
    "roomDetails": "Detail kamar",
    "roomNb": "Kamar {0}",
    "roomPlan": "Nama rencana",
    "roomRate": "Tarif Kamar",
    "roomType": "Tipe ruangan",
    "roomWithChildren": "{nRoom} kamar untuk {nAdult} dewasa dan {nChild} anak-anak",
    "roomWithoutChildren": "{nRoom} kamar untuk {nAdult} dewasa",
    "searchNecessary": "*Nomor reservasi ini diperlukan ketika Anda memperbarui/membatalkan reservasi atau menanyakan mengenai reservasi kepada kami.",
    "serviceFee": "Biaya jasa",
    "selected": "Anda memilih",
    "subTotal": "Subtotal",
    "subTotalWithoutTax": "Subtotal (tanpa pajak)",
    "tax": "Pajak",
    "taxIncluded": "Termasuk pajak",
    "total": "Jumlah Pembayaran (Termasuk Pajak)",
    "totalAmount": "Jumlah Total",
    "totalLengthOfStay": "Total lama menginap",
    "totalPriceWithTax": "Total harga termasuk pajak",
    "depositAmount": "Jumlah setoran ({0}%)",
    "remainAmount": "Jumlah tersisa",
    "withTax": "dengan pajak",
    "depositNecessary": "Anda perlu membayar deposit sebesar {0}% melalui sistem online kami untuk menyelesaikan konfirmasi pemesanan."
  },
  "reservationPayment": {
    "creditCardPayment": "Prabayar dengan kartu kredit",
    "onSitePayment": "Bayar di hotel (Tidak perlu pembayaran di muka)",
    "paymentMethod": "Cara Pembayaran",
    "pendingPage": {
      "launchButton": "Aman 3D",
      "text01": "Klik tombol untuk merujuk ke layar entri kata sandi 3D Secure.",
      "text02": "Jika entri kata sandi tidak selesai dalam waktu 15 menit, proses reservasi akan dibatalkan.",
      "text03": "Reservasi belum selesai sampai Anda memasukkan kata sandi, sehingga lowongan mungkin akan diambil.",
      "text04": "Pembayaran kartu kredit akan ditangani oleh tripla, sehingga \"tripla\" akan ditampilkan di nama toko 3D Secure.",
      "timeOutError01": "Terjadi kesalahan batas waktu.",
      "timeOutError02": "Pembayaran tidak diselesaikan dalam kursus normal. Namun, jika ada biaya yang dikenakan pada kartu kredit, maka akan dikembalikan. Harap konfirmasi rincian jadwal pembayaran dan pengembalian dana dengan perusahaan kartu kredit.",
      "authError01": "Proses pembayaran gagal.",
      "error01": "Terjadi kesalahan saat proses reservasi.",
      "error02": "Pembayaran tidak diselesaikan dalam kursus normal. Namun, jika ada biaya yang dikenakan pada kartu kredit, maka akan dikembalikan. Harap konfirmasi rincian jadwal pembayaran dan pengembalian dana dengan perusahaan kartu kredit.",
      "error03": "Klik tombol \"Kembali\" dan pesan lagi dari layar hasil pencarian."
    },
    "planError": "Maaf, paket yang dipilih memiliki jenis pembayaran yang bertentangan. Silakan coba lagi.",
    "selectCreditCards": "Pilih kartu kredit",
    "sessionErrorMessage": "Ada yang salah. Silakan kembali ke halaman Detail Tamu dan coba lagi.",
    "sessionErrorButtonText": "Kembali ke Detail Tamu",
    "gmoCreditCardMaintenanceWarning": "Mulai pukul 04.00 hingga 06.00 (Waktu Jepang) pada tanggal 18 November 2024, merek kartu tidak akan tersedia karena pemeliharaan sistem. Silakan pilih metode pembayaran alternatif atau tunggu hingga pemeliharaan selesai.",
    "gmoOnSiteCreditCardRequiredMaintenanceWarning": "Dari pukul 04.00 hingga 06.00 (Waktu Standar Jepang) pada tanggal 18 November 2024, registrasi kartu tidak akan tersedia karena pemeliharaan sistem. Silakan pilih metode pembayaran alternatif atau tunggu hingga pemeliharaan selesai.",
    "gmoMcpMaintenanceWarning": "Dari pukul 04.00 hingga 06.00 (Waktu Standar Jepang) pada tanggal 18 November 2024, VISA dan Mastercard tidak dapat digunakan karena pemeliharaan sistem. Silakan pilih metode pembayaran alternatif atau tunggu hingga pemeliharaan selesai."
  },
  "reservationReceipt": {
    "customerName": "Nama pelanggan untuk tanda terima",
    "deferredWithoutDownload": "Tanda terima tidak akan dikeluarkan untuk pembayaran yang ditangguhkan.",
    "download": "Unduh tanda terima",
    "downloadOnCheckout": "Tanda terima Anda dapat diunduh pada tanggal check-out dan kapan saja setelahnya.",
    "downloadAtSpecificTime": "Tanda terima Anda dapat diunduh pada tanggal {date} pukul {time} dan kapan saja setelahnya.",
    "onSite": "Untuk pembayaran di tempat, tanda terima akan dikeluarkan di meja depan hotel."
  },
  "searchBar": {
    "addRoom": "Tambahkan ruangan lain",
    "adult": "{n} Dewasa | {n} Dewasa | {n} Dewasa",
    "adults": "Dewasa",
    "age": "Usia",
    "agree": "Setuju",
    "bed": "Tempat tidur",
    "checkIn": "Mendaftar",
    "checkInAndOut": "Tanggal check in - check out",
    "checkOut": "Periksa",
    "child": "{n} Anak | {n} Anak | {n} Anak-anak",
    "childN": "Anak {n}",
    "children": "Anak-anak",
    "childrenAgeAtCheckout": "Usia anak-anak saat checkout",
    "childrenAgeInfant": "Usia {dari}-{sampai}",
    "childrenAgePrimary": {
      "primary": "Usia {dari}-{sampai}",
      "primaryBottom": "Usia {dari}-{sampai}",
      "primaryTop": "Usia {dari}-{sampai}"
    },
    "childrenAges": "usia anak-anak yang bepergian dengan Anda?",
    "childrenAgeSharedBed": "Usia {dari}-{sampai}",
    "childrenMealBedding": {
      "meal_no": "Tidak ada makan & tempat tidur",
      "meal_no_bedding": "Hanya tempat tidur",
      "meal_same_as_adult_bedding": "Makan & tidur",
      "meal_yes": "Hanya makan",
      "meal_yes_bedding": "Makan & tidur"
    },
    "cookieConsent": "Situs web ini menggunakan cookie untuk meningkatkan pengalaman pengguna Anda. Dengan terus menggunakan situs web ini, Anda telah menyetujui persetujuan cookie kami. Untuk informasi lebih lanjut, silakan periksa {0}.",
    "privacyPolicy": "Kebijakan Pribadi",
    "perRoomGuests": "Jumlah tamu per kamar",
    "dates": "tanggal",
    "dayUse": "Pesan untuk penggunaan harian saja",
    "defaultArea": "Lokasi mana pun",
    "edit": "Sunting",
    "filter": "Saring",
    "guests": "Tamu",
    "hotel": "Hotel",
    "maxChildrenWarning": "Jumlah maksimum anak per orang dewasa adalah {n}",
    "pleaseSelectHotel": "Silakan pilih hotel",
    "room": "{n} Kamar | {n} Kamar | {n} Ruangan",
    "rooms": "Kamar",
    "search": "Mencari",
    "showOnlyAvailable": "Hanya tampilkan yang tersedia",
    "sort": "Urutkan",
    "sortOption": {
      "price_high_to_low": "Harga Tinggi ke Rendah",
      "price_low_to_high": "Harga Rendah ke Tinggi",
      "recommended": "Direkomendasikan",
      "asc": "Harga Rendah ke Tinggi",
      "desc": "Harga Tinggi ke Rendah"
    },
    "to": "ke",
    "undated": "Tanggal belum diputuskan",
    "nights": "malam",
    "yearOld": "{n} tahun | {n} tahun | {n} tahun",
    "enableWBFSearch": "Aktifkan Pencarian Paket Hotel + Penerbangan",
    "stayFlightTab": "Menginap + Penerbangan",
    "stayTab": "Tinggal",
    "processInJapTxt": "Proses reservasi tersedia secara eksklusif dalam bahasa Jepang.",
    "processMaxTxt": "Proses reservasi tersedia secara eksklusif dalam bahasa Jepang, dengan maksimal 5 kamar untuk pemesanan Menginap+Penerbangan.",
    "undatedWarning": "Pencarian tanpa menentukan tanggal check-in tidak tersedia jika jumlah orang di setiap kamar berbeda.",
    "undatedWarning2": "Ini tidak tersedia ketika mencari dengan “Penerbangan + Kamar”.",
    "flightAndRoomsWarning": "Ini tidak tersedia ketika mencari dengan “Tanggal belum diputuskan”."
  },
  "searchReservation": {
    "enterDetail": "Masukkan detail Anda di bawah.",
    "enterReservationNumber": "Masukkan nomor reservasi Anda",
    "manageBooking": "Kelola pemesanan Anda",
    "searchFailed": "Kami memerlukan informasi berikut untuk melanjutkan. Sayangnya, kami tidak mengenali email atau nomor reservasinya. Silakan coba lagi.",
    "confirmReservation": "Konfirmasi Reservasi",
    "confirmReservationDesc": "Karena pemesanan ini dibayar oleh {ota}, Anda akan dialihkan ke {ota} dalam {time} detik."
  },
  "searchResultByPlan": {
    "hide": "Bersembunyi",
    "planContent": "Deskripsi rencana",
    "plans": "Paket",
    "rooms": "Kamar",
    "showPlan": "Tampilkan detail paket",
    "moreDetail": "Keterangan lebih lanjut"
  },
  "snsLogin": {
    "loginWith": "Masuk dengan {0}",
    "enterPassword": "Masukkan kata sandi akun keanggotaan Anda untuk melanjutkan",
    "existingPrompt": "Sepertinya Anda sudah memiliki akun. Apakah Anda ingin menghubungkan akun Anda dengan {sns}? Anda akan dapat masuk dengan {sns} dan email/kata sandi biasa.",
    "signUpDescription": "Terima kasih telah menghubungkan akun {sns} Anda, harap berikan informasi sisanya untuk menyelesaikan pendaftaran akun."
  },
  "voucher": {
    "cancel": "Membatalkan",
    "confirm": "Mengonfirmasi",
    "number": "Nomor voucher",
    "purchseComplete": "Pembelian selesai",
    "selectStay": "Silakan pilih rencana perjalanan Anda",
    "stillVoucher": "Ini masih berupa voucher. Check-in/out belum dipilih sebagai reservasi yang dikonfirmasi.",
    "thankPurchase": "Terima kasih atas pembelian Anda.",
    "update": "Memperbarui",
    "updateItinerary": "Perbarui rencana perjalanan Anda",
    "updateItineraryDesc": "Silakan pilih tanggal check-in dan tanggal check-out yang diinginkan untuk memperbarui rencana perjalanan.",
    "usingVoucher": "Pesan kamar menggunakan voucher ini",
    "valid": "Voucher sekarang berlaku",
    "voucherDesc": "Anda dapat memesan kamar menggunakan voucher ini dengan memilih tanggal check-in/out di sini.",
    "voucherDetails": "Detail voucher",
    "voucherNumber": "Nomor voucher"
  },
  "triplaPay": {
    "notFoundMsg": "Catatan pembayaran tidak ditemukan. Transaksi Anda tidak akan selesai.",
    "isPaid": "Pembayaran ini berhasil diselesaikan",
    "isAlreadyPaid": "Sudah dibayar",
    "isFailed": "Pembayaran gagal",
    "isCompleted": "Pembayaran selesai",
    "isRefunded": "Pembayaran Anda telah dikembalikan",
    "refundedAt": "Tanggal pengembalian dana",
    "paidAt": "Tanggal pembayaran",
    "isCancelled": "Pembayaran dibatalkan",
    "total": "Jumlah pembayaran",
    "pleaseReload": "Pekerjaan ini tidak selesai dalam satu jam. Silakan muat ulang.",
    "reload": "Muat ulang",
    "subject": "Subjek Pembayaran",
    "amountIsChanged": "Jumlah pembayaran telah diubah, silakan klik tombol \"Perbarui\" untuk menyegarkan.",
    "terms": "Dengan menyetujui tripla {0}, {1}, dan {2}, Anda memahami bahwa sistem pembayaran ini disediakan oleh tripla Co., Ltd. dan informasi pribadi Anda akan dibagikan kepada tripla Co., Ltd.",
    "paypalTerms": "PayPal digunakan untuk pembayaran kartu kredit, dan akan dicantumkan pada laporan kartu Anda.",
    "temsOfUse": "syarat Penggunaan",
    "privacyPolicy": "Kebijakan pribadi",
    "gdprPolicy": "Kebijakan GDPR",
    "pleaseScan": "Silakan pindai kode QR"
  },
  "triplaPayIssues": {
    "INVALID_PARAMETER_SYNTAX": "Mohon tinjau kembali informasi yang Anda berikan.",
    "INVALID_EXPIRATION_YEAR": "Harap pastikan tanggal kedaluwarsa Anda sudah benar.",
    "Invalid_card_number": "Harap konfirmasi nomor kartu.",
    "Others": "Mohon tinjau kembali informasi yang Anda berikan."
  },
  "lottery": {
    "registerForLottery": "Daftar Lotere",
    "selectedDatesError": "Lama menginap yang dipilih harus berada dalam jangka waktu menginap maksimum dan minimum yang diperbolehkan untuk acara lotere ini. ({0} ~ {1} malam)"
  },
  "recommenderWidget": {
    "previewText": "Ini adalah mode pratinjau, hanya berlaku selama 5 menit. Silakan klik 'Pratinjau' lagi setelah 5 menit. Ingatlah untuk menyimpan pengaturan Anda untuk versi live sebenarnya."
  }
}