export default {
  "additionalQuestion": {
    "adultFemale": "女性",
    "adultMale": "男性",
    "adultOther": "其他",
    "answerQuestions": "請回答以下追加問題。",
    "checkinTime": "入住時間",
    "checkoutTime": "退房時間",
    "moreDetailed": "需要知道關於您的入住詳情",
    "notificationFromFacility": "有關設施的注意事項",
    "selectTime": "時間選擇",
    "view": "檢視",
    "customizeYourStayExp": "客製化住宿體驗",
    "customizeYourStayExpDesc": "請問可以回答幾個額外的問題嗎？"
  },
  "turnstile": {
    "unsupportedBrowser": "您使用的瀏覽器不受支援。請使用 Chrome、Firefox、Safari 或 Microsoft Edge。"
  },
  "base": {
    "account": "帳號",
    "all": "全部",
    "apply": "應用",
    "attachFile": "附加文件",
    "backTo": "返回{0}",
    "booking": "預約一覽",
    "browserWarning": "您目前所使用的瀏覽器無法開啟官方網站。請安裝最新的瀏覽器版本後再嘗試開啟。",
    "cancel": "取消",
    "close": "關閉",
    "confirm": "確定預約",
    "continue": "下一步",
    "collapse": "縮小",
    "delete": "刪除",
    "edit": "變更",
    "error": "很抱歉，系統發生錯誤，請再試一次。",
    "error02": "不好意思,處理過程時系統發生錯誤, 請稍後再試。",
    "errorPaymentMethod": "因各房間選擇了不同的支付方式，故無法繼續預約程序。請選擇同一種支付方式，或是分別預約。",
    "oldErrorCode": {
      "1403": "此賬號已被設施註冊，需要重設密碼，請返回登入頁面，點擊“忘記密碼”。"
    },
    "errorCode": {
      "1501": "此優惠券已達到使用限額，無法再次使用",
      "1903": "可預約的期間為今天起 {reservable_period_months} 個月。\n請指定可預約期間的日期。",
      "2304": "此方案的設置與您的賬戶狀態不符。請刷新頁面並重新預訂，敬請諒解。",
      "2401": "您只有{currentPoint}點數。無法使用{spendPoint}點數。",
      "3001": "發生超時錯誤。支付尚未處理。",
      "3002": "發生錯誤，付款處理失敗。您的預訂尚未完成。對此造成不便，我們深表歉意，但請再試一次。",
      "3003": "此信用卡無法使用。",
      "3004": "請確認信用卡號碼。",
      "3005": "請確認有效日期是否正確。",
      "3006": "3D驗證失敗。",
      "3007": "信用卡輸入錯誤或無法使用。",
      "3008": "3D驗證已被取消。",
      "3009": "因多方要求，我們已經暫停3D驗證。",
      "3011": "發生超時錯誤。支付尚未處理。",
      "3013": "由於此訂單正在進行更新或處理付款問題，目前無法更改此預訂內容，請在大約5分鐘後再試。\"",
      "3014": "由於原本預訂內容已更新，請取消並重新確認新內容後再進行預約變更。",
      "3016": "由於此訂單正在進行更新或處理付款問題，目前無法更改此預訂內容，請在大約5分鐘後再試。\"",
      "3202": "無法獲得點數餘額。",
      "3203": "不好意思,處理過程時系統發生錯誤, 請稍後再試。",
      "3204": "付款過程失敗，請使用其他卡，或稍後重試。",
      "3205": "此信用卡無法使用。",
      "3250": "3D驗證失敗。",
      "3251": "不好意思,處理過程時系統發生錯誤, 請稍後再試。",
      "3252": "輸入的卡不支援3D 驗證。",
      "3502": "無法獲得點數餘額。",
      "3504": "點數無法返還。請確認點數記錄。",
      "3750": "這些日期僅適用於抽獎活動。詳情請查看飯店網站。",
      "3751": "已不提供此抽獎活動，詳情請查看飯店網站。",
      "3752": "已不提供此抽獎活動，詳情請查看飯店網站。",
      "3753": "已不提供此抽獎活動，詳情請查看飯店網站。",
      "3754": "已不提供此抽獎活動，詳情請查看飯店網站。",
      "3756": "您之前已參加了此次抽獎，請等待抽獎結果。如要確認參加紀錄，可點擊您會員資料頁中的「預約內容」，在「抽獎」部分查看您的參與記錄。",
      "3757": "已不提供此抽獎活動，詳情請查看飯店網站。",
      "3760": "已不提供此抽獎活動，詳情請查看飯店網站。",
      "3780": "您的鏈結已過期或無效，請重新查看飯店網站並再次參與抽獎活動。",
      "3781": "您的鏈結已過期或無效，請重新查看飯店網站並再次參與抽獎活動。",
      "3782": "您的鏈結已過期或無效，請重新查看飯店網站並再次參與抽獎活動。",
      "3783": "您的鏈結已過期或無效，請重新查看飯店網站並再次參與抽獎活動。"
    },
    "sessionExpired": "會話過期。請刷新頁面繼續。",
    "notAvailableYet": "此操作尚不可使用，請稍後再試。",
    "fileLimit80MB": "上傳圖片的上限為一張80MB",
    "upTo30FilesError": "不能附加超過 30 個文件",
    "upTo30Files": "最多30個文件",
    "textLengthLimit": "回答字數需在255字以內。",
    "triplaCoLtd": "tripla (株)",
    "triplaAi": "tripla.ai",
    "GDPR": "GDPR政策",
    "here": "此處",
    "imageError": "圖片讀取失敗",
    "join": "會員註冊",
    "languages": {
      "en": "English",
      "ja": "日本語",
      "ko": "한국어",
      "zh_Hans": "简体中文",
      "zh_Hant": "繁體中文"
    },
    "loading": "搜尋中",
    "search": "搜尋",
    "login": "登入",
    "logout": "退出",
    "logoutMessage": "您已登出帳號",
    "signInStatusChangeMessage": "由於登入狀態已變更，您將被重定向到搜尋畫面。",
    "memberSignIn": "會員登入",
    "memberSignUp": "首次登記網上會員",
    "next": "下一步",
    "notSelected": "尚未選擇",
    "noData": "沒有數據",
    "optional": "選填",
    "pay": "支付",
    "previous": "上一頁",
    "previousPage": "上一頁",
    "privacyPolicy": "隱私權條款",
    "readMore": "顯示更多",
    "readLess": "隱藏",
    "recommended": "推薦",
    "remove": "刪除",
    "required": "必填",
    "requireMessage": "必填欄位不能為空。",
    "reservationStatuses": {
      "cancelled": "已取消",
      "done": "已預約"
    },
    "save": "保存",
    "signInUp": "登入/註冊",
    "submit": "提交",
    "termError": "請同意使用條款",
    "taxMessage": "現在顯示的價格為稅前的價格加上8%的消費稅。關於2019年10月後的預約, 會因日本消費稅調漲的關係而有異動, 詳細的金額請參考預約確認畫面的最終金額。",
    "termsOfUse": "使用條款",
    "topPage": "主頁",
    "update": "更新",
    "success": "成功",
    "unsubscribe": "取消訂閱",
    "unsubscribeConfirmation": {
      "error": "出現錯誤。請稍後再試。",
      "title": "取消訂閱嗎？",
      "description": "如果希望取消訂閱促銷電子郵件，請點擊按鈕。"
    },
    "unsubscribeSuccessDescribe": "您已取消訂閱。我們不會再向您發送促銷電子郵件。",
    "warning": {
      "kidsChanged": "因此設施不對應指定的搜索條件，關於兒童的搜索條件已變更。"
    },
    "formalName": "{name}",
    "maintenanceTitle": "此網站目前正在定期維護中。",
    "maintenanceDesc": "造成不便，我們深表歉意。維護完成前請稍等一下。",
    "noResultAvailable": "無匹配的搜尋結果",
    "taxAndServiceFeeIncluded": "含消費稅和服務費"
  },
  "roomLottery": {
    "updateLottery": "變更抽獎申請",
    "restoreLottery": "復原抽獎申請",
    "updateDisabledDesc": "本次抽獎活動的參與期已結束，請等待結果。",
    "viewUpdateDetails": "查看/變更內容",
    "restoreUpdateEntry": "變更/復原內容",
    "updateProfileDesc": "當公開結果後，如您獲獎及進行下一步預訂時，上述提供的信息將被用於預約過程及無法更改。",
    "cancellationEmailDesc": "我們已發送取消電子郵件至 {0}",
    "cancelModalTitle": "取消抽獎申請",
    "cancelModalDesc": "您希望取消抽獎申請嗎？此操作將無法撤回。",
    "cancelEntry": "取消申請",
    "reservationDeadlinePassed": "預訂截止日期已過",
    "reservationDeadlinePassedDesc": "非常抱歉，但預訂截止日期已過。關於詳情，請瀏覽飯店網站或直接聯繫咨詢。",
    "accountNotMatched": "此帳戶與申請抽獎時所使用的帳戶不符。請使用正確的帳戶登入。",
    "resultDateNotice": "抽獎結果將於{date}（{day}）{time}～公佈。",
    "resultDateNotice02": "只有獲獎的人會收到電子郵件，其中包含如何預訂的說明。",
    "resultDateNotice03": "您可以隨時在會員選單的「預約內容」內查看狀態。",
    "lotteryEntryNumber": "申請受理號碼",
    "lotteryEntryConfirmed": "抽獎申請已確認",
    "lotteryEntryCancelled": "您的抽獎申請已取消",
    "lotteryResultNotSelected": "非常抱歉，您没有獲獎。",
    "notSelectedNotice": "感謝您參加此活動，我們期待下次見到您。",
    "lotteryResultSelectedNotified": "🎉 您已被選為抽獎獲勝者！",
    "lotteryWinnerNotice01": "※預訂尚未確認。請完成預約程序。",
    "lotteryWinnerNotice02": "預約截止日期至 {date} ({day}) {time}",
    "manageYourLottery": "管理抽獎",
    "reservationIncomplete": {
      "errorMessage": "其他預訂正在處理中。如果繼續預訂，所有其他預訂都將被取消。",
      "checkboxMessage": "同意取消其他預訂。",
      "modalMessage": "其他預訂已處理完成，現在顯示預約資訊。"
    }
  },
  "bookingDetail": {
    "overlappedReservation": "您預訂的的入住日和退房日期與{0}重疊，請預訂前再次確認。\n*重疊的預訂可能是此品牌下的其他設施。",
    "overlappedReservationLink": "之前的預訂",
    "overlappedReservationGuest": "您預訂的的入住日和退房日期與目前的預訂重疊，請預訂前再次確認。\n*重疊的預訂可能是此品牌下的其他設施。\n*如果已使用其他設備取消預訂，則此檢測可能有誤，請檢查您收到的郵件進行確認。",
    "overlappedReservationGuest2": "*如果已使用其他設備取消預訂或清除cookies，則此檢測可能有誤，請檢查您收到的郵件進行確認。"
  },
  "bookingResult": {
    "paymentPromotionBanner": {
      "description": "此折扣適用於我們網站上的預訂並使用信用卡提前付款。",
      "message": "使用信用卡付款可享獨家 {0} 折扣。",
      "limitedTimeOnly": "優惠期限內有效！",
      "title2": "使用信用卡付款更划算！",
      "title3": "使用信用卡付款可享{0}折扣"
    },
    "allUser": "所有用戶",
    "availableBetween": "有效期間",
    "bath": "浴室",
    "shower": "淋浴",
    "bathtub": "浴缸",
    "bedCount": "{n}張床",
    "book": "預約",
    "bookAirline": "附機票的預訂",
    "flightRooms": "附機票的預訂",
    "bookAirlineWarning": "{0}選項僅適用於{1}的入住期間。請調整入住日期才可使用此選項。",
    "bookAirlineWarningStay": "{0}選項僅適用於最長{1}天的住宿日數。請調整住宿日數才可使用此選項。",
    "cancellationFreeUntil": "{0}之前(設施當地時間) 都可免費取消",
    "cancellationPolicy": "取消政策",
    "discountAmount": "折扣額度",
    "excludeDuring": "除外期間",
    "forNights": "住宿{n}晚",
    "freeWifi": "免費WiFi",
    "followingSearchConditions": "該日程没有空房。",
    "guests": "入住人數",
    "includes": "包括",
    "planInformation": "包括",
    "lowestPriceInfo": "根據空房狀況和折扣條件，日曆上可能無法顯示最低價格。關於實際的價格，請確認日曆。",
    "maxAdults": "可最多容纳{n}位大人入住。",
    "meals": "餐點",
    "memberExclusive": "會員獨享",
    "membersOnly": "會員限定",
    "nonSmoking": "禁菸",
    "noRoomFound": "非常抱歉，按您輸入的條件，未能搜到有空房。",
    "tryChanging": "請嘗試{0}或設施。",
    "tryItem": "其他日期",
    "noRoomSelected": "尚未選擇客房",
    "noRoomsAvailable": "非常抱歉，按您輸入的條件，未能搜到有空房。",
    "noRoomsAvailableDesc": "請嘗試其他日期或設施。",
    "notAvailable": "無法預約",
    "priceFor": "價格適用於",
    "priceForAdult": "大人 × {n}",
    "priceForChild": "兒童 × {n}",
    "priceForRoom": "房間",
    "paymentCreditCard": "信用卡支付",
    "paymentMethods": "付款方式",
    "paymentMethod": {
      "on_site": "當地支付",
      "credit_card": "信用卡支付",
      "on_site_credit_card_required": "現場支付（需事前輸入信用卡）",
      "deferred_payment": "公司代付",
      "credit_card_full": "信用卡支付全額",
      "credit_card_deposit": "信用卡支付訂金",
      "bank_transfer": "銀行轉帳",
      "credit_card_krw_domestic": "網上支付（韓國國內信用卡）",
      "krw_domestic_description": "樂天卡目前無法使用，但很快就會開放使用。",
      "credit_card_krw_international": "網上支付（國際信用卡）"
    },
    "paymentNewCreditCards": "以新的信用卡來支付",
    "paymentRegisteredCreditCards": "以登記過的信用卡來支付",
    "planDetails": "方案內容",
    "plansLimitForUpdating": "您無法選擇變更最初所選擇的住宿方案。",
    "price": "金額",
    "promotionMayChanged": "可能會變更或提早終止促銷活動，恕不另行通知。",
    "registerCard": "登記此信用卡",
    "regularRate": "標準價格",
    "reservationStartDate": "預約開始日期",
    "showRoomDetails": "顯示客房詳情",
    "roomDetails": "客房詳情",
    "roomFeatures": "客房詳情",
    "roomLeft": "僅剩{n}間房！",
    "roomAvailable": "剩{n}間房",
    "roomEnough": "尚有空房",
    "roomRate": "價格",
    "rooms": "房間數",
    "roomSize": "客房大小",
    "roomSizeUnit": {
      "tatami_mats": "榻榻米"
    },
    "select": "選擇",
    "selectNewDate": "重新選擇日期",
    "serviceFeeDesc": "代理預約網站的最終價格已含服務費。",
    "sleeps": "入住人數",
    "smoking": "吸菸",
    "stopReservationUpdate": "停止預約更新程序",
    "stopLotteryEntryRestore": "停止變更抽獎申請程序",
    "stopLotteryEntryUpdate": "停止復原抽獎申請程序",
    "targetUser": "目標用戶",
    "taxExcluded": "未含稅",
    "taxIncluded": "含稅",
    "toilet": "洗手間",
    "totalPrice": "日元",
    "totalPriceForNights": "住宿{n}晚合計價格 | 住宿{n}晚合計價格 | 住宿{n}晚合計價格",
    "viewAll": "顯示全部",
    "viewClose": "隐藏",
    "viewGallery": "查看所有照片",
    "viewLess": "隱藏",
    "viewMore": "顯示全部的住宿方案 ({n}件)",
    "viewAllRooms": "查看所有{n}間客房",
    "wifi": "WiFi",
    "wifiOption1": "無",
    "wifiOption2": "免費",
    "wifiOption3": "需付費",
    "searchResults": "搜尋結果:",
    "nPlans": "{n} 件",
    "nRoomTypes": "{n} 件"
  },
  "customAuth": {
    "failTitle": "登錄失敗",
    "failDesc": "因會員信息未註冊，所以無法登錄。"
  },
  "cancellationPolicy": {
    "policyHeader": "取消費詳細如下：",
    "policyLine": "入住前 {0} 日：{1}％ 的費用",
    "policyLineWithTime": "入住前 {0} 日 {1} 之後：{2}% 的費用",
    "freePolicyLine": "入住前 {0} 日：0％ 的費用",
    "noShowLine": "未入住且未通知：{0}％ 的費用",
    "cancelOnTheDay": "入住當日：{0}％ 的費用",
    "cancelOnTheDayFree": "入住當日 {0} 之前：免費取消",
    "cancelOnTheDayCharge": "入住當日 {0} 之後：{1}％ 的費用",
    "cancelBeforeOnTheDayCharge": "入住當日 {0} 之前：{1}％ 的費用",
    "uponLocalTime": "所有的取消政策都基於預約設施的當地時間計算。",
    "uponLocalTimeOTA": "所有的取消政策都基於預約設施的當地時間計算。",
    "ifBookedElsewhere": "透過其他訂房網站或旅行社訂房的旅客請由該網站或者直接向旅行社諮詢",
    "specialRate": "特別取消率: {0}",
    "guest": "客人",
    "policyWithTimeEachDayCharge": "入住前 {0} 日 {1} 以后：{2}％ 的費用  ",
    "policyWithBeforeTimeEachDayCharge": "入住前 {0} 日 {1} 以前：{2}％ 的費用",
    "policyWithTimeEachDayFree": "入住前 {0} 日 {1} 以前：免費"
  },
  "calendar": {
    "fullyBooked": "全被訂滿",
    "roomsLeft": "剩餘空房數",
    "selected": "已選擇的日期",
    "unavailable": "已無空房",
    "unavailableNow": "暫不可預訂"
  },
  "corporateAdmin": {
    "admin": "管理者",
    "checkInDate": "入住日",
    "download": "下載",
    "guestName": "預約者姓名",
    "hotelName": "客房名",
    "maxNumberStay": "最多住宿天數",
    "maxNumberStays": "最多住宿天數",
    "nMonths": "{n}個月",
    "nights": "入住天數",
    "numberOfRooms": "房間數",
    "remaining": "尚可預約的天數",
    "reservationData": "預約資訊",
    "reservationDataDesc1": "※使用詳情僅顯示透過本官網預約的數目的合計。所以，透過其他渠道（電話等）的預約將不會顯示，敬請注意。",
    "reservationDataDesc2": "※預約・住宿完成後，將會需要數分鐘~數十分鐘的時間在本官網顯示，敬請見諒。",
    "reservationDataDesc3": "※此畫面將不會顯示透過非本官網的其他渠道進行的住宿內容變更及取消。",
    "reservationDate": "預約日期",
    "reservationNumber": "預約號碼",
    "reserved": "已住宿天數",
    "status": "狀態",
    "title": "企業管理者",
    "totalPrice": "價格",
    "period": "期間",
    "year": "年"
  },
  "creditCardForm": {
    "enterYourPaymentDetails": "輸入付款信息",
    "addNewCard": "輸入新的信用卡",
    "cardExpiration": "有効期限",
    "cardExpirationConfirm": "請再次確認信用卡有效期限",
    "cardHolder": "信用卡姓名",
    "cardHolderConfirm": "請再次確認信用卡姓名",
    "cardHolderFormatError": "請用羅馬拼音輸入姓名。",
    "cardHolderHelpOTA": "請輸入持卡者姓名(與信用卡上一致)",
    "cardHolderHelp": "請輸入持卡者姓名(與信用卡上一致)",
    "cardNumber": "信用卡號碼",
    "cardNumberConfirm": "請再次確認信用卡號碼",
    "cardNumberHelp": "輸入您的信用卡號碼",
    "cardRequired": "信用卡的信息僅是為了確保預約，我們不會從此信用卡扣款。但是若是依照{0}產生取消費用的情況，則會從此卡扣取。",
    "cardSecurityCode": "安全碼(CVV)",
    "cardSecurityCodeConfirm": "請再次確認信用卡安全碼",
    "cardSecurityCodeHelp": "CVV",
    "ccvExampleOTA": "列於（Visa、Mastercard、JCB 和 Diners Club）卡背面或（American Express）卡正面的3或4位數字。",
    "ccvExample": "印在信用卡背面（Visa、Mastercard、JCB）或正面（American Express）的3位或4位數字。用於驗證使用者是否合法持卡人，以及防止在線購物時的詐騙事件。",
    "creditCard": "支援信用卡類別",
    "expiredError": "請輸入在退房當日有效的信用卡。",
    "month": "月",
    "payInAdvanceOTA": "若是經由{0}的預約, 煩請使用信用卡先行支付費用。",
    "payInAdvance": "若是經由{0}的預約, 煩請使用信用卡先行支付費用。",
    "payInAdvanceVoucher": "若是經由{0}的預約, 煩請使用信用卡先行支付費用。",
    "payByCreditCard": "完成預訂時，將由旅行代理店 ({0}) 付款。",
    "payByCreditCardConsent": "您的資訊將被妥善分享給旅行代理公司tripla株式会社以完成預定。繼續此預訂流程即表示您同意{0}、{1}以及{2}的相關條款。",
    "tappayNote": "我們使用TapPay 2048-bit SLL支付系統。我們不會保存任何持卡人的信息。TapPay透過遵守 Service Provide Level 1 所認證的PCI DSS，來保護您的信用卡資訊。",
    "year": "年",
    "cardBrand": "信用卡組織",
    "cardBrandMismatch": "請輸入正確的卡號或選擇正確的信用卡組織",
    "paymentOverMillionError": "在線支付的金額超過100萬日元則無法使用 VISA卡 和Master卡。請使用其他信用卡類別或選擇其他付款方式。",
    "badCards": "由於系統變更，新的預訂無法使用以下的卡。非常抱歉，請重新輸入卡的信息。",
    "gmoBadCards": "由於系統變更，我們目前暫時不支援可保存信用卡資訊的功能。每次使用信用卡支付時都需要重新輸入信用卡資訊。造成您的不便, 我們深感抱歉, 也非常感謝您的配合與諒解。",
    "pontaVisaCheckbox": "使用Route Inn Hotels PontaVISA卡時，請選擇「Route Inn Hotels PontaVISA卡」作為卡片品牌。如果您選擇「VISA」，則不會獲得獎勵積分。",
    "creditCardInvalid": "無效",
    "creditCardInvaildHoverText": "由於系統變更，新的預訂無法使用以下的卡。非常抱歉，請重新輸入卡的信息。",
    "rrn": "出生日期（YYMMDD）",
    "firstTwoDigitOfPin": "PIN 碼的前兩位數字",
    "payDirectlyOnsitePayment": "請直接在飯店付款",
    "payByLocalCurrency": "請使用JCB/AMEX/Diners 信用卡以當地貨幣付款、或直接在飯店付款。",
    "exploreLocalCurrency": "或搜尋其他以當地貨幣支付的方式",
    "exploreOther": "或搜尋其他付款方式"
  },
  "gmoPaypalOptions": {
    "null": "請選擇信用卡組織",
    "visa": "VISA",
    "ponta_visa": "VISA (Route Inn PontaVISA card)",
    "master": "Mastercard",
    "mastercard": "Mastercard",
    "amex": "American Express",
    "jcb": "JCB",
    "diners": "Diners Club"
  },
  "downloadPasswordModal": {
    "label": "為了讓信息加密，請輸入密碼。輸入此密碼，下載的zip文件才可解壓。"
  },
  "extraItems": {
    "error": {
      "extraNumber": "請變更{name}的數目。可能預約的數目為{min}〜{max}。"
    }
  },
  "extras": {
    "dateSelectTitle": "選擇新增加購項目的日期",
    "dateSelectBtn": "前往選擇數量",
    "goToGuestDetails": "輸入顧客資訊",
    "stock": "庫存",
    "selectableQuantity": "可選數量",
    "remainingExtras": "剩餘數量"
  },
  "facilitySuggestion": {
    "distanceFrom": "距離：距此設施{n}千米",
    "from": "最優惠價格",
    "header": "附近可入住的設施",
    "soldOut": "搜索不到空房。請更改條件，重新搜尋。",
    "soldOutDesc": "非常抱歉，按您搜索的條件，未找到空房。",
    "visitHotelWebsite": "打開設施的頁面"
  },
  "guestDetail": {
    "afterUpdate": "更改後",
    "bookingForSomeone": "若僅幫忙訂房，不會同行，請打勾。",
    "brrDiscount": "最優惠房價折扣",
    "confirmUpdates": "確定變更預約",
    "confirmPayByCard": "確定以信用卡支付",
    "coupon": "優惠碼",
    "couponApplied": "已使用",
    "couponAppliedNew": "適用的優惠券：",
    "creansmaerdCouponApplied": "已使用優惠碼{name}。",
    "couponCode": "優惠代碼",
    "couponDiscount": "優惠碼折扣",
    "couponExpired": "非常抱歉, 此優惠碼已失效。",
    "couponInvalid": "優惠碼輸入錯誤。",
    "couponLimitReached": "因使用上限的原因，我們不再提供此優惠碼{code}。",
    "couponMaxStays": "此優惠碼的折扣僅適用於最開始的{n}晚。之後的天數按普通價格預約。",
    "couponTargetPlans": "優惠碼適用方案",
    "couponNotUse": "不使用優惠碼",
    "couponValidUntil": "有效期至 {date}",
    "couponUsageQuantityLimit": "一次只可使用一個優惠券代碼。請刪除已添加的才可使用新的代碼。",
    "creditCardWarning": "原本價格, 即最初預約的費用，會退還至您的信用卡，並以變更完成後的總價, 即最新價格重新付費。",
    "availableCoupons": "可使用優惠券",
    "viewMoreCoupons": "查看更多優惠券",
    "removeCoupon": "刪除",
    "day": "第 {n} 天",
    "email": "電子郵箱(請指定可接收{email})",
    "emailAgain": "電子郵箱(確認用)",
    "emailAgainConfirm": "電子郵箱及確認用電子郵箱有異",
    "emailAgainHelp": "請再次輸入電子郵箱",
    "emailConfirm": "請輸入正確的電子郵箱",
    "emailHelp": "請輸入電子郵箱",
    "extras": "加購項目",
    "extraLeft": "剩 {count} {unit}",
    "extraOutOfStock": "沒有庫存",
    "fullName": "姓名",
    "fullNameError": "請輸入姓名的羅馬拼音。",
    "goPreviousScreenConfirm": "返回上一個畫面？",
    "goPreviousScreenDescription": "請注意，在此頁面上輸入的所有資訊都没有儲存。",
    "goPreviousScreenContinue": "繼續",
    "guestDetails": "顧客資訊",
    "guestName": "顧客姓名",
    "guestNameError": "請輸入姓名的羅馬拼音。",
    "guestNameHelp": "請輸入顧客名稱",
    "guestNameKanji": "住宿者姓名(漢字)",
    "guestStayName": "顧客名稱",
    "mainGuest": "主要聯絡人",
    "notes": "備註",
    "originalPaymentAmount": "原本價格",
    "promotionDiscount": "折扣",
    "phone": "電話號碼",
    "phoneConfirm": "請提供正確的電話號碼",
    "phoneHelp": "請輸入您的電話號碼",
    "priceBecomeCheaper": "由於促銷活動內容會根據住宿設施的不同而有所變更，目前選擇的住宿方案的價格比之前更加優惠。請確認現在的價格，然後進行預約\r\n",
    "priceBecomeExpensive": "非常抱歉，由於促銷活動內容會根據住宿設施的不同而有所變更，目前選擇的住宿方案的價格比之前有所提高。請確認現在的價格，然後進行預約。",
    "priceDiff": "差價",
    "signupTerms": "本預約系統為tripla(株)提供, 顧客的個人資訊會與tripla(株)共享。我同意tripla{0}、{1}以及{2}。",
    "terms": "提供此預訂系統的tripla(株)將共享您的個人資訊。本預訂完成後, 即表示已經同意tripla{0}、{1}和{2}。",
    "totalAmount": "合計金額",
    "totalAmountWithTax": "合計金額(含稅)",
    "totalWithoutTax": "合計金額(未含稅)",
    "updateUserInformation": "更新以上除電子郵件以外關於會員信息的修改。",
    "cheaperAccommodationTax": "由於兌換了點數，預訂的住宿稅也減少了。請在預訂前查看最新住宿費。",
    "roomGuestName": "房間 {0} 顧客姓名",
    "confirmation": {
      "title": "標示 {0} 的欄位為必需填寫的。",
      "description": "預訂後無法更改顧客資訊。請務必輸入顧客本人的姓名。"
    }
  },
  "inventoryCalendar": {
    "availableIconDescription": "{icon}：有空房",
    "unavailableIconDescription": "{icon}：没有空房",
    "uncertainIconDescription": "{icon}：剩餘少量"
  },
  "lineLogin": {
    "buttonText": "使用LINE帳號登入"
  },
  "meta": {
    "facilities": "品牌設施",
    "sign_in": "登入",
    "organization_sign_in": "企業或組織登入頁面",
    "sign_up": "會員註冊",
    "search_results": "住宿方案一覽表",
    "guest_detail": "輸入顧客資訊",
    "payment_method": "輸入付款方法",
    "lotteryCompleted": "完成抽獎申請",
    "completed": "完成預約",
    "reservationIncomplete": "未完成的預約",
    "my_account": "我的頁面",
    "otaHome": "AI智選您的理想飯店",
    "otaHomeDescription": "tripla提供2000多個精選住宿，支持AI搜索。從傳統的旅館到豪華度假勝地，輕鬆找到您完美的住宿。",
    "otaSearchHotels": "飯店搜尋結果",
    "otaSearchResults": "住宿方案一覽表",
    "otaGuestDetail": "輸入顧客資訊",
    "otaPayment": "輸入付款方法",
    "otaConfirmation": "完成預約",
    "otaBookingConfirmation": "預約確認"
  },
  "membership": {
    "promotionRate": "促銷折扣",
    "regularRate": "標準價格",
    "signUpFor": "會員價格"
  },
  "membershipForm": {
    "address": "地址",
    "alreadyHaveAccount": "已有帳號",
    "back": "上一頁",
    "buildingName": "建物名稱與房號",
    "changePassword": "重新設定密碼",
    "changePasswordResult": "重新設定密碼",
    "changePasswordResult02": "✓ 顧客的密碼已重新設定。",
    "changePasswordResult03": "請點此重新登入。",
    "changePasswordSecondary": "請輸入您的新密碼",
    "confirmDeletedResult": "您已經成功退會。",
    "confirmNewPassword": "確認新密碼",
    "confirmPassword": "確認密碼",
    "confirmRegisterFail": "註冊失敗",
    "confirmRegisterFail02": "煩請再次註冊。",
    "confirmRegisterResult": "電子郵箱認證",
    "confirmRegisterResult02": "✓ 已完成註冊手續",
    "confirmRegisterResult03": "盡情享受住宿的時光",
    "createAccount2": "會員註冊",
    "createAccount3": "會員註冊",
    "createAccount4": "建立新帳戶",
    "dob": "出生日期",
    "email": "電子郵箱",
    "emailError": "請確認您的電子信箱地址。",
    "emailPromotionCheck": "接收來自飯店的促銷郵件。",
    "english_error": "請輸入英文拼音",
    "locale_error": {
      "ja": "請輸入英文拼音",
      "zh_Hant": "請輸入英文拼音或繁體中文",
      "ko": "請輸入英文拼音"
    },
    "enterDetail": "請輸入您的資料",
    "first_name": "名字(英文拼音)",
    "firstNameLocales": {
      "ja": "名字(英文拼音)",
      "zh_Hant": "名字",
      "ko": "名字(英文拼音)"
    },
    "first_name_kanji": "",
    "first_name_kanji_placeholder": "",
    "first_name_placeholder": "Tai Man",
    "firstNamePlaceHolderLocales": {
      "ja": "Tai Man",
      "zh_Hant": "大明",
      "ko": "Tai Man"
    },
    "firstName": "名字",
    "firstNameError": "請輸入您的名字。",
    "firstNameKana": "",
    "forgotPassword": "忘記密碼？",
    "forgotPassword02": "重新設定密碼",
    "forgotPasswordError": "您輸入的電子郵箱尚未註冊為會員",
    "forgotPasswordResult": "重新設定密碼",
    "forgotPasswordResult02": "✓ 已寄送再次設定密碼的電子郵件至您的電子郵箱。",
    "forgotPasswordResult02New": "✓ 收到 {email} 的密碼重設請求。",
    "forgotPasswordResultSecondary": "寄送電子郵件需要稍許時間。若在10分鐘後尚未收到電子郵件時, 煩請點擊下方的「再次寄送」按鈕。",
    "forgotPasswordResultSecondaryNew": "如果您輸入的電子郵箱與帳戶關聯，您將會收到一封重設密碼的電子郵件。此電子郵件可能需要10 分鐘才能送達。在進行另一次重設之前，請先檢查您的垃圾郵件資料夾。",
    "forgotPasswordResultSecondary02New": "如果您在10分鐘內沒有收到電子郵件，則您可能沒有帳戶。請嘗試使用其他電子郵箱或{0}。",
    "forgotPasswordSecondary": "我們將再次寄送設定密碼的方法, 請輸入您的電子郵箱帳號。",
    "gender": "性別",
    "generalError": "不好意思,處理過程時系統發生錯誤, 請稍後再試。",
    "guest_first_name": "名字(英文拼音)",
    "guest_first_name_kanji": "",
    "guest_last_name": "姓氏(英文拼音)",
    "guest_last_name_kanji": "",
    "header": "會員註冊",
    "hiragana_error": "",
    "kanji_katakana_error": "",
    "kanji_error": "",
    "last_name": "姓氏(英文拼音)",
    "lastNameLocales": {
      "ja": "姓氏(英文拼音)",
      "zh_Hant": "姓氏",
      "ko": "姓氏(英文拼音)"
    },
    "last_name_kanji": "",
    "last_name_kanji_placeholder": "",
    "last_name_placeholder": "Chan",
    "lastNamePlaceHolderLocales": {
      "ja": "Chan",
      "zh_Hant": "王",
      "ko": "Chan"
    },
    "lastName": "姓氏",
    "lastNameError": "請輸入您的姓氏。",
    "lastNameKana": "",
    "membershipId": "會員號碼",
    "membershipProgram": "會員制度",
    "name": "名字",
    "nameJaPlaceholder": "",
    "nameJaValidation": "",
    "nameInvalidCharMessage": "您輸入的字符包含此系統無法支援的字符。",
    "nameKanaJaPlaceholder": "",
    "namePlaceholder": {
      "en": "請輸入英文",
      "ja": "請輸入平假名或英文",
      "zh_Hant": "請輸入漢字或英文",
      "zh_Hans": "請輸入漢字或英文",
      "ko": "請輸入韓文或英文"
    },
    "nameValidation": {
      "ja": "請輸入平假名或英文",
      "zh_Hant": "請輸入漢字或英文",
      "zh_Hans": "請輸入漢字或英文",
      "ko": "請輸入韓文或英文"
    },
    "nationality": "國籍",
    "newPassword": "新密碼 (請輸入8個或以上的字符, 必須包含大寫和小寫英文字母、數字及符號。)",
    "noAccount": "希望成為我們的會員嗎?",
    "occupation": "職業",
    "or": "或",
    "orContinueBelow": "或輸入下方資料",
    "password": "密碼",
    "passwordConfirmationError": "密碼與確認密碼不吻合",
    "passwordError": "請確認您的密碼。",
    "passwordSignUp": "密碼 (請輸入8個或以上的字符, 必須包含大寫和小寫英文字母及數字。)",
    "passwordNote": "請輸入8個或以上的字符, 必須包含大寫和小寫英文字母及數字。",
    "pleaseSelect": "选择会员制度",
    "postCode": "郵遞區號",
    "postCodeError": "僅可輸入英文字母和數字。",
    "prefecture": "州份/行政區域/省份",
    "notApplicable": "不適用",
    "privacyPolicy": "隱私權政策",
    "promotionsCheckbox": "我想收到email的特別優惠和促銷訊息",
    "resend": "再次寄送",
    "resendConfirmationEmail": "未收到會員註冊確認郵件的情況",
    "resendConfirmationEmailError": "此電子郵箱已被註冊。",
    "resendConfirmationEmailTitle": "再次發送會員註冊確認郵件",
    "receiptName": "收據抬頭",
    "receiptUnicode": "收據統編",
    "send": "寄送",
    "setPassword": "設定",
    "setup": "會員註冊",
    "setupResultSecondary01": "如果您想要再次嘗試註冊會員，請點擊{0}。",
    "setupResultSecondary02": "已寄送確認郵件至登錄的電子郵箱。\r\n目前為暫時登錄的狀態。請點擊郵件中的URL以完成會員登錄的手續。\r\n登錄完成後即可登入。",
    "signIn": "登入",
    "signInHere": "登入",
    "signInTo": "登入{0}",
    "signUp": "註冊會員帳號",
    "signUpResult": "已傳送確認電子郵件",
    "signUpResult02": "已完成註冊手續",
    "signUpResult02Secondary": "感謝您申請成為我們%{hotel_name}的會員。",
    "signUpResult02Secondary02": "A confirmation email has been sent to the email address you supplied.",
    "signUpResultSecondary": "感謝您申請成為我們%{hotel_name}的會員。",
    "signUpResultSecondary02": "為了完成註冊手續, 煩請確認您的電子郵件。",
    "signUpResultSecondary03": "請點擊郵件中的URL完成註冊。",
    "signUpResultSecondary04": "已完成其會員註冊手續。",
    "signUpSecondary": "僅需輸入以下資料即可註冊成為會員",
    "term": " 我同意{0}及{1}。",
    "termError": "Please accept to the terms and conditions",
    "termOfUse": "使用規則",
    "wifi": "Free in-room wifi",
    "yetReceived": "沒有收到確認郵件？{0}",
    "resendEmail": "按此處再發送",
    "pleaseResetPassword": "您所輸入的電子郵件帳號的註冊已經確認，所以無需重新發送確認電子郵件。\n如果您知道密碼，請{0}。\n如果您忘記了密碼，請使用{1}選項。",
    "pleaseResetPasswordLink1": "直接登錄",
    "pleaseResetPasswordLink2": "密碼重置",
    "receiptNameFormatError": "收據抬頭格式錯誤, 請輸入正體中文",
    "receiptUnicodeFormatError": "統一編號格式錯誤, 請輸入8位數字",
    "receiptNameRequiredError": "請輸入收據抬頭",
    "receiptUnicodeRequiredError": "請輸入統一編號"
  },
  "membersihp": {
    "target": "適用會員"
  },
  "multipleCurrency": {
    "JPY": "日圓",
    "ARS": "阿根廷比索",
    "AUD": "澳幣",
    "BRL": "巴西雷亞爾",
    "CAD": "加幣",
    "CNY": "人民幣",
    "CZK": "捷克克朗",
    "DKK": "丹麥克朗",
    "EUR": "歐元",
    "HKD": "港幣",
    "INR": "印度盧比",
    "IDR": "印尼盾",
    "ILS": "以色列新謝克爾",
    "MOP": "澳門帕卡塔",
    "MYR": "馬來西亞林吉特",
    "MXN": "墨西哥比索",
    "TWD": "新台幣",
    "NZD": "紐西蘭元",
    "NOK": "挪威克朗",
    "PHP": "菲律賓比索",
    "GBP": "英鎊",
    "QAR": "卡達裡亞爾",
    "RUB": "俄羅斯盧布",
    "SAR": "沙烏地阿拉伯裡亞爾",
    "SGD": "新加坡元",
    "ZAR": "南非蘭特",
    "KRW": "韓元",
    "LKR": "斯里蘭卡盧比",
    "SEK": "瑞典克朗",
    "CHF": "瑞士法郎",
    "THB": "泰銖",
    "AED": "阿聯酋迪拉姆",
    "USD": "美元",
    "VND": "越南盾",
    "modalTitle": "選擇貨幣",
    "modalDescription": "請選擇一種貨幣。如果您選擇日元以外的貨幣，您的付款方式將僅限於信用卡。",
    "suggestedCurrencies": "常用貨幣",
    "allCurrencies": "所有貨幣",
    "creditCardWarning": "由於變更付款方法，付款貨幣同時有更改。請確認金額。",
    "exchangeRateUpdatedError": "匯率已更新，請查看最新價格並預訂。",
    "currency": "貨幣"
  },
  "myAccount": {
    "availablePoints": "可使用{0}",
    "availablePointsDesc": "目前持有和可使用的{0}。",
    "totalRankingPoints": "您持有{amount}等級{pointName}。",
    "changeLoginEmail": "變更電子郵箱",
    "changeLoginEmailDescription": "輸入新的電子郵箱後, 確認郵件會自動寄送至該電子郵箱。點擊郵件中的URL後即可完成變更。若電子郵箱輸入錯誤時, 只要完成變更電子郵箱手續, 系統會自動寄送確認郵件至最後更新的電子郵箱裡。",
    "changeLoginEmailPendingText": "目前還在確認新的電子郵箱。請點擊更新時發的郵件中的URL查看狀態。",
    "changePassword": "重新設定密碼",
    "confirmLoginEmail": "新的電子郵箱（確認中）",
    "edit": "編輯",
    "emailLabel": "電子郵箱",
    "loginEmail": "電子郵箱",
    "loginInfo": "登錄信息",
    "notRegistered": "未有信用卡登錄",
    "pageTitle": "帳號資訊",
    "password": "密碼",
    "passwordLabel": "密碼",
    "paymentInfo": "支付信息",
    "paymentOptions": "支付方法",
    "paymentOptionsDesc": "這是已登錄信用卡的清單。所有與信用卡相關的資訊將嚴格保密。",
    "pendingEmailText": "{n} 電子郵箱確認中",
    "personalInfo": "個人信息",
    "promotions": "優惠券",
    "point": "點數",
    "removeAccount": "退會",
    "removeAccountConfirmationText": "是否確認退會?",
    "removeAccountReason01": "不會再次使用",
    "removeAccountReason02": "住宿費太貴",
    "removeAccountReason03": "對會員優待不感興趣",
    "removeAccountReasonInput": "請在此輸入理由",
    "removeAccountReasonOthers": "其他",
    "removeAccountSecondaryText": "退會後所有的會員優待將無效。",
    "removeAccountSecondaryText02": "預約消息也將不再顯示。",
    "removeCreditCard": "刪除信用卡",
    "removeCreditCardConfirm": "您確定要刪除登錄的信用卡嗎？",
    "resendEmail": "再次寄送確認郵件",
    "resendEmailSuccess": "已經再次寄送確認郵件",
    "yourCreditCards": "已登錄信用卡",
    "membershipId": "會員編號",
    "myQRCode": "我的二維碼",
    "promotion": "促銷",
    "historyStatus": {
      "expired": "已過期的點數",
      "future": "將獲得的點數",
      "spent": "已使用的點數",
      "gained": "獲得的點數",
      "refunded": "退還的點數"
    },
    "value": "價值",
    "badCards": "由於系統變更，新的預訂無法使用此卡。",
    "unlockRank": "在{date}之前賺取{points} {pointName}，便可達到{rank}等級。",
    "unlockRankNoDate": "只要賺取{points} {pointName}，便可達到{rank}等級。",
    "earnMorePointsToKeepRank": "要保持目前的{rank}等級，您必須在{date}之前賺取{points} {pointName}。",
    "achievedHighest": "恭喜！您已獲得最高等級。",
    "achievedHighestOnDate": "恭喜！您已達成條件，並將在{date}獲得最高等級。"
  },
  "myProfile": {
    "cancel": "取消您的預約",
    "cancelled": "已取消",
    "change": "變更",
    "checkIn": "入住日期",
    "checkOut": "退房日期",
    "confirmationNo": "預約號碼",
    "multiRoom": "多種房型",
    "myReservations": "預約內容",
    "myPage": "個人頁面",
    "noResultButtonText": "搜尋空房",
    "noResultText": "您尚未預約客房",
    "pageTitle": "帳戶情報",
    "viewDetails": "查看詳細內容",
    "viewFlightDetails": "確認附機票預訂的詳情",
    "myVoucher": {
      "amount": "金額",
      "askConsume": "使用優惠券嗎?",
      "askConsumeMessage": "此優惠券只能使用一次。",
      "cancel": "取消",
      "consume": "使用",
      "consumeVoucher": "使用優惠券",
      "excludeDuring": "除外期間",
      "makeReservation": "預訂",
      "targetStayingPeriod": "適用的入住期間",
      "validThrough": "適用的販賣期間",
      "name": "名稱",
      "consumptionTime": "使用時間",
      "reservationNumber": "預約號碼",
      "status": {
        "status": "狀態",
        "available": "可使用",
        "pending": "保留中",
        "expired": "已過期",
        "consumed": "已使用"
      },
      "selectHotel": "選擇飯店"
    },
    "lottery": {
      "status": {
        "registered": "進行中",
        "not_winner": "未獲獎",
        "winner_reservation_confirmed": "獲獎(已預訂)",
        "winner_reservation_cancelled": "預訂已取消",
        "registration_cancelled": "抽獎已取消",
        "winner_reservation_pending_declined": "抽獎已取消",
        "winner_reservation_pending": "獲獎(未預訂)"
      },
      "text": "抽獎"
    },
    "processBooking": "進行預訂",
    "cancelEntry": "取消申請",
    "lotteryEntryNo": "申請受理號碼"
  },
  "mcp": {
    "chooseCurrency": "選擇您的首選貨幣",
    "recommendLocalCurrency": "我們建議選擇 VISA 或 Mastercard 以當地貨幣付款，以避免額外費用。目前不支援使用其他信用卡進行多種貨幣的交易付款。"
  },
  "organization": {
    "enterDetail": "請輸入帳戶ID及密碼",
    "signIn": "企業或組織登入頁面"
  },
  "ota": {
    "changeLoginEmailPendingText": "目前還在確認新的電子郵箱。請點擊更新時發的郵件中的URL查看狀態。",
    "name": {
      "agoda": "Agoda",
      "booking_com": "Booking com",
      "expedia": "Expedia",
      "jalan": "Jalan",
      "rakuten_travel": "Rakuten Travel",
      "rakutenTravel": "Rakuten Travel"
    },
    "priceNotAvailable": "暫未提供",
    "loadingTitle0": "探索完美住宿體驗",
    "loadingDescription0": "AI客服為您推薦最適合飯店。您的夢想住宿上傳中...",
    "loadingTitle1": "為您的日本體驗升級",
    "loadingDescription1": "一起沉浸於豐富的日本文化，體驗傳統的茶道，享受抹茶製作藝術，從中品味寧靜。",
    "loadingTitle2": "期待的神秘景點",
    "loadingDescription2": "闖入鮮為人知的社區，探索人跡罕至的奇觀。一起發現當地寶藏，感受這座城市的真正魅力。",
    "loadingTitle3": "聯繫傳統",
    "loadingDescription3": "請感受日本藝妓者的魔力。透過觀賞傳統舞蹈表演或體驗茶道，感受日本文化。",
    "loadingTitle4": "美食佳餚",
    "loadingDescription4": "參觀人氣的美食市場，深入了解日本料理的多元化。從品嘗壽司的鮮味至香噴可口的章魚燒，滿足您的味蕾。",
    "loadingTitle5": "大自然的寧靜",
    "loadingDescription5": "逃離城市的喧囂，漫步迷人的竹林。體驗這座歷史名城的寧靜和永恆之美。",
    "loadingTitle6": "櫻花盛況",
    "loadingDescription6": "櫻花滿開時來一趟日本! 感受被櫻花染成一片粉紅的壯麗景觀，參與當地的花見慶祝活動，享受一趟奇妙之旅。",
    "fromBwToOtaPaymentError": "出現錯誤。請按「下一步」按鈕或選擇不同的付款方式重試。",
    "fromBwToOtaRestoreDataError": "出現錯誤。請重新輸入資料並再次按「下一步」按鈕。",
    "searchHotels": "搜尋飯店",
    "aiRecommendations": "AI住宿推薦",
    "recommendationsForU": "您的最佳住宿",
    "interestedIn": "你可能也會對這些感興趣...",
    "home": "首頁",
    "showingCountAvailable": "正顯示{total}中的{count}飯店清單",
    "standardTravelAgency": "旅行業條款",
    "termsOfUse": "用戶條款",
    "privacyPolicy": "隐私政策",
    "companyProfile": "公司簡介",
    "bookingSupport": "聯絡我們",
    "search": "搜尋飯店",
    "checkAvailability": "查看空房",
    "avgPricePerNight": "每晚參考房價",
    "adults": "大人{n}名",
    "GDPR": "GDPR 保護規範",
    "homepage": {
      "recommend1": "周末想找一家在東京經濟實惠的飯店。",
      "recommend2": "幫我找下沖繩的度假飯店，3位。",
      "recommend3": "想找一家京都旅館為妻子慶祝生日。",
      "recommend4": "下個月要前往神戶出差，有沒有推薦的一人住飯店？",
      "placeholder": "例：我想要在新宿附近的飯店預訂一個雙人房間,三晚。"
    },
    "bookingConfirmation": "預約確認",
    "confirmToOtaTitle": "預訂確認流程",
    "confirmToOtaDescription": "接下來，我們將引導您前往旅行代理店的官方網站({0})，在那裡完成預付款操作後，您的預訂將正式成立。",
    "confirmToOtaDescriptionInGuestForm": "接下來，我們將引導您前往旅行代理店的官方網站({0})，在那裡完成預付款操作後，您的預訂將正式成立。",
    "confirmToOtaDescriptionInGuestFormOnsiteCC": "接下來，我們將引導您前往旅行代理店的官方網站({0})，在那裡完成信用卡註冊操作後，您的預訂將正式成立。\n\n",
    "aiSearchNoResult": "我們的AI正在學習中，無法根據您輸入的關聯詞提供建議，請提供更多詳細信息，例如地點、人數、設施資訊等。此外，以下的建議您可能感興趣。"
  },
  "otaRoomsResult": {
    "noRoomsAvailable": "非常抱歉，您輸入的條件没有空房。",
    "noRoomsAvailableDesc": "請選擇其他日期，或更改入住人數後再次搜尋是否有適合客房。",
    "location": "位置",
    "showAllPhotos": "顯示所有圖片"
  },
  "otaRoomsMap": {
    "title": "地圖",
    "seeMap": "查看地圖"
  },
  "paymentMethod": {
    "paymentKRWCreditCardFooterTitle": "線上付款由以下公司處理：",
    "paymentKRWCreditCardFooterLine1": "Tripla Co., Ltd.（韓國分公司）",
    "paymentKRWCreditCardFooterLine1CEO": "CEO: Kazuhisa Takahashi",
    "paymentKRWCreditCardFooterLine2": "商業登記號碼：538-84-00072",
    "paymentKRWCreditCardFooterLine3": "3F Concordian Building, 76 Saemunan-ro, Jung-gu, Seoul, South Korea (P. 03185)",
    "paymentKRWCreditCardFooterLine4": "support_ko{'@'}tripla.io",
    "paymentKRWCreditCardFooterLine4Link": "mailto:support_ko{'@'}tripla.io",
    "paypalCheckbox1": "請務必使用預約者本人的信用卡。如果預約者和持卡人不同，可能會在沒有通知的情況下取消。",
    "paypalCheckbox2": "基於發卡公司需要檢測或者防止非法使用信用卡的事件，本公司從客戶收集的以下個人信息等將提供給客戶使用的發卡公司。",
    "paypalCheckbox3": "基於發卡公司需要檢測或者防止非法使用信用卡的事件，本公司從客戶收集的以下個人信息等將提供給客戶使用的發卡公司。姓名、電話號碼、電子郵箱、互聯網使用環境的信息等。",
    "paypalCheckbox4": "如果客房所使用的發卡公司在日本國外，這些信息可能會轉移到發卡公司所屬的國家。",
    "paypalCheckbox5": "如果客戶為未成年人士，請在父母或者法定監護人的同意下使用本服務。",
    "pre_paid": "信用卡支付",
    "credit_card": "預結算",
    "payments": {
      "credit_card": "信用卡支付"
    },
    "deferred_payment": "公司代付",
    "on_site": "入住時付款\n",
    "on_site_credit_card_required": "入住時付款\n",
    "type": "支付方式",
    "twd_credit_card": "信用卡支付全額",
    "twd_credit_card_deposit": "信用卡支付訂金",
    "bank_transfer": "銀行轉帳",
    "paymentPromotion": "付費促銷",
    "paymentPromotionTooltip": "可能會變更或提早終止促銷活動，恕不另行通知。",
    "undefined": ""
  },
  "pointHistory": {
    "availableAt": "有效使用",
    "cancellation": "取消",
    "dateColumn": "日期",
    "descriptionColumn": "詳細內容",
    "failToLoadErrorMessage": "很抱歉，點數紀錄載入失敗",
    "noData": "您目前沒有任何點數的紀錄",
    "pointExpirationColumn": "點數失效期限",
    "pointHistory": "點數紀錄",
    "pointsColumn": "點數",
    "pointUse": "使用點數",
    "stay": "住宿",
    "viewMore": "顯示更多",
    "exchangeDigico": "{point}轉換",
    "exchangeDigicoDesc": "請輸入您希望轉換為Digico點數的{point}數目。Digico點數可轉為用於Amazon、Apple、Google的點數。一經轉換將無法為您復原。轉換後的Digico點數僅有180天的有效期。此服務僅可在日本進行。轉換後，設施將無法回答關於Digico點數的問題。關於Digico點數的更多詳情，請查看{link}。",
    "exchangeDigicoLink": "點此",
    "exchangeDigicoLog": "Digico點數轉換",
    "exchangeAvaliablePoints": "可轉換的{point}合計",
    "exchangeBtn": "轉換",
    "exchangeHistory": "{point}轉換",
    "exchangedAmount": "轉換金額",
    "exchangedDigicoCode": "代碼",
    "exchangeConfirm": "一經轉換將無法為您復原。您確定希望轉換{point}嗎？",
    "exchangeConfirmBtn": "繼續",
    "digicoPointUrl": "Digico點數轉換URL：",
    "invalidAmount": "請輸入有效的點數數。",
    "totalBalance": "總共點數",
    "currentPoints": "可用的點數",
    "futurePoints": "將獲得的點數"
  },
  "pointSpending": {
    "balance": "可使用的點數",
    "errors": {
      "incorrectAmount": "如要使用點數,需以每{n}點來使用。",
      "moreThanPrice": "使用的點數無法超過所需的付款金額。",
      "upToBalance": "您輸入的點數已超出持有的額度, 請修改。",
      "pointUsageError": "總付款金額若為0，是無法進行信用卡支付的。為了啟用線上支付，您可以在上一步減少點數的使用。"
    },
    "minimumAmount": "使用點數時, 最低需使用{0}點數。",
    "points": "分",
    "saveForLater": "這次無法使用點數",
    "updateRule": "您無法使用已用於原來預約的點數。我們會在更新預約後返還點數。",
    "useAll": "使用全部的點數",
    "useAmount": "只用部分點數",
    "title": "點數"
  },
  "points": {
    "naming": {
      "default": "點數",
      "history": "{point}履歴",
      "available": "可使用{point}",
      "future": "將獲得{point}",
      "expired": "已過期{point}",
      "gained": "獲得{point}",
      "spent": "已使用的{point}",
      "save": "保存{point}以備後用",
      "redeem": "兌換全部{point}",
      "redeem2": "兌換最多{point}。",
      "refunded": "退還的{point}",
      "miniSpending": "最少使用{point}必須為 {number} 或更多。",
      "useIncrements": "您可以以{number}遞增使用{point}",
      "discount": "{point} 優惠",
      "earn": "預訂此房間可獲得{number} {point} ",
      "checkOut": "{point}將在退房日的3天後添加。",
      "recalculateOnCheckoutDate": "顯示的 {point} 金額為估計值​​。確切的金額將在退房當天重新計算。",
      "usePoint": "使用{point}",
      "empty": "目前沒有任何{point}記錄。",
      "total": "總共",
      "availableBalance": "可使用的{point}",
      "redeemRemark": "只用部分{point}",
      "cannotUse": "您無法使用已用於原來預約的{point}。我們會在更新預約後返還{point}。"
    },
    "getRegisterPoint": "註冊成為會員獲得{amount} {points_name}"
  },
  "progressBar": {
    "completeLottery": "完成抽獎申請",
    "completeLotterySubtitle": "您已完成抽獎申請",
    "confirmationSubtitle": "您的房間已經預約",
    "confirmationSubtitleOTA": "您的房間已經預約",
    "confirmationTitle": "預約完成",
    "confirmationTitleOTA": "完成預約",
    "guestSubtitle": "請填寫您的資訊",
    "guestTitle": "顧客資訊",
    "paymentSubtitle": "選擇付款方式",
    "paymentTitle": "付款"
  },
  "reservation": {
    "cancelled": "已取消",
    "completed": "已完成",
    "upcoming": "即將到來",
    "lottery": "抽獎",
    "filtersLabel": "選擇條件",
    "filters": {
      "all": "全部",
      "onSiteChecked": "當地支付",
      "on_site_credit_card_required": "當地支付",
      "otaPrePaidChecked": "透過 {0} 以信用卡付款",
      "bookingWidgetPrePaidChecked": "信用卡支付",
      "deferredPaymentChecked": "公司代付"
    },
    "reservedChannel": {
      "all": "全部",
      "title": "預約渠道",
      "officialWebsite": "官網 (當地支付)",
      "wbf": "附機票套餐",
      "tripla": "旅遊預訂網站（信用卡付款）"
    },
    "signInUpReminder": "登入/註冊以獲得會員福利"
  },
  "reservationAction": {
    "contactTriplaSupport": "聯繫tripla客服部",
    "applyGOTO": "使用優惠碼",
    "bookingCancel": "取消您的預約",
    "bookingUpdate": "更新顧客資訊",
    "corporateSignInRequired": "公司法人若希望更新預約，需登錄帳戶。",
    "differentFacilityWarning": "為了更新您的預約，請透過已預約的設施登錄。",
    "everythingCorrectSubtitle": "您可以在此處更改您的資料或取消預約。",
    "everythingCorrectTitle": "管理您的預約",
    "invoiceDownload": "列印此頁",
    "madeMistakeHelp": "我們期待下次能再為您服務。",
    "madeMistakeTitle": "您的預約已經取消。",
    "memberSignInRequired": "需登錄會員後，才可更新預約。",
    "pleaseCancelAll": "若想取消所有的客房, 請直接取消預約。",
    "payByCreditCard": "更改為信用卡事前支付",
    "reductionOfNights": "縮短住宿期間",
    "reductionOfRooms": "減少客房數",
    "selectRoomsToCancel": "請選擇想取消的客房",
    "signOutRequired": "需退出登錄會員後，才可更新預約。",
    "updateExpired": "此預約已超過可變更的期限, 故無法更改。",
    "updateItinerary": "變更預約",
    "differentAccountWarning": "請使用正確的帳戶登錄以更新預訂",
    "differentOrgWarning": "請使用正確的公司法人帳戶登錄以更新預訂",
    "coupon": {
      "signOutRequired": "請退出帳戶後才申請使用優惠券。",
      "memberSignInRequired": "需登錄會員後，才可使用優惠券。",
      "corporateSignInRequired": "需登錄公司法人的帳戶後，才可使用優惠券。",
      "differentFacilityWarning": "如果想使用優惠券，請透過已預約的設施登錄。",
      "differentAccountWarning": "請使用正確的帳戶登錄以使用優惠券。",
      "differentOrgWarning": "請使用正確的公司法人帳戶登錄以使用優惠券。"
    },
    "notEnoughStays": "所選的住宿期間應等於或大於此預約所設定的最少住宿天數（{0}晚）。",
    "viewMyReservations": "查看我的預約",
    "madeMistakeHelp2": "我們期待再次為您服務",
    "madeMistakeTitle2": "已取消預約"
  },
  "reservationCancellation": {
    "roomN": "房型 {0}",
    "cancellationFees": "取消費用",
    "cancellationConfirm2": "確定要取消預約嗎？",
    "reason2": "請問取消的原因",
    "reasonTripCancelled2": "旅行計劃改變",
    "reasonRoomCheaper2": "其他公司比較划算",
    "reasonNbTrvellers2": "住宿人數改變",
    "reasonDifferentHotel2": "選擇了另一家飯店",
    "reasonOther2": "其他（請註明）",
    "cancel": "取消預約",
    "cancellationConfirm": "確定要取消此預約嗎？",
    "cancellationFee": "取消費用",
    "cancellationOnDay": "當日取消無法透過網路申請。",
    "cancellationPolicy": "取消政策",
    "cancellationTitle": "取消您的預約",
    "contactByChatPhone": "煩請透過電話聯繫。",
    "contactByPhone": "煩請透過電話聯繫。",
    "contactPhone": "電話號碼 {0}",
    "keep": "保留預約",
    "nonRefundable": "無法退款",
    "reason": "請提供您想取消的理由",
    "reasonDifferentHotel": "我找到另一家飯店",
    "reasonNbTrvellers": "行程的人數有所變更",
    "reasonOther": "其他",
    "reasonOtherHelp": "若選擇其他, 請提供詳細的內容",
    "reasonRoomCheaper": "其他網頁的房價更便宜",
    "reasonTripCancelled": "我的行程已經取消",
    "roomNbCancelFee": "房間 {0} 的取消費用",
    "totalCancelFee": "取消費用總額",
    "changePlanQuestion": "需要更改計劃嗎？",
    "changePlanDesc": "除了取消預約，您也可以更改預訂日期以便安排日程。請靈活調整日程，以確保適合您的住宿體驗。",
    "changePlanWarnTL": "* 原本預訂的住宿方案不可更改",
    "cancelTitle": "取消您的預訂",
    "cancellationNote": "請注意，取消預訂可能會產生取消費用。",
    "cancellationReason": "取消原因：",
    "cancellationTellUs": "請告知您取消的原因：",
    "reasonPlanChange": "旅行計劃變更",
    "reasonAffordableOption": "已找到更實惠的選擇",
    "reasonNumberAdjustment": "調整後的旅客人數",
    "reasonHotelAlt": "選擇了另一家飯店",
    "reasonOthers": "（請註明）"
  },
  "reservationConfirmation": {
    "bookingConfirmed": "您的預訂已確認",
    "cancelledSuccessfully": "預訂成功取消",
    "totalStays": "入住天數",
    "contactProperty": "聯絡資訊",
    "yourRoomDetails": "房間詳細信息",
    "phone": "電話號碼",
    "email": "電子郵箱",
    "website": "網頁",
    "cancellationFreeUntil": "{0} 之前都可免費取消",
    "cannotChangePlan": "您無法變更住宿方案。",
    "confirmationEmail": "我們已經向您的電子郵箱{0}發送相關郵件。",
    "extra": {
      "answer": "回答",
      "dateTime": "日期 & 時間",
      "extrasPrice": "追加選項",
      "items": "物品",
      "notes": "備註",
      "price": "價格",
      "priceIncludeTax": "價格(含稅)",
      "question": "問題",
      "roomPrice": "客房價格總額"
    },
    "extrasSubtotal": "追加選項價格總額",
    "nonRefundableReminder": "此預約是不設退款, 一經預訂, 取消時將不獲任何退款。",
    "originalReservationPlans": "此預約所選擇的住宿方案。",
    "reservationDetail": "預約內容",
    "selectNewItinerary": "請重新選擇住宿期間及入住人數並開始搜索。",
    "updateBooking": "變更預約",
    "cannotUpdateTooltip": "因為設施工作人員之前根據您的同意修改了價格及預約內容，目前您無法更新/取消預訂。"
  },
  "reservationDetail": {
    "bookingDetails": "確認預訂的詳情",
    "adult": "{n}位",
    "accommodationTax": "住宿稅",
    "checkIn": "入住日期",
    "checkOut": "退房日期",
    "child": "{n}位",
    "dayUse": "當日休息專案",
    "details": "詳細資訊",
    "detailsTitle": "預訂詳情",
    "discount": "折扣",
    "discounts": "折扣",
    "discountedPrice": "點數優惠",
    "guest": "{n}位",
    "guests": "入住人數",
    "hotel": "飯店",
    "maxGuests": "最多可容納人數",
    "night": "{n} 晚",
    "nights": "入住天數",
    "numberOfGuest": "人數",
    "numberOfNights": "入住天数",
    "numberOfPeople": "人數",
    "numberOfAdults": "{n} 位成人",
    "numberOfChildren": "{n} 位兒童",
    "originalPrice": "總價",
    "pointDiscount": "點數兌換金額",
    "pointInstruction": "退房後的第三天可獲得點數",
    "pointReward": "此預約可獲得{0}分",
    "priceBreakDown": "收費明細",
    "priceDetails": "收費明細",
    "reservationDetails": "預約內容",
    "reservationNumber": "預約號碼",
    "roomDetails": "訂房資訊",
    "roomNb": "房型 {0}",
    "roomPlan": "住宿方案",
    "roomRate": "客房價格",
    "roomType": "房型",
    "roomWithChildren": "{nRoom} 間房間容納 {nAdult} 位成人和 {nChild} 位兒童",
    "roomWithoutChildren": "{nRoom} 間房間容納 {nAdult} 位成人",
    "searchNecessary": "* 當您需要更改或取消預約時均需要提供此預約編號, 建議您打印此頁。",
    "serviceFee": "服務費",
    "selected": "已選擇",
    "subTotal": "小計",
    "subTotalWithoutTax": "小計(未含稅)",
    "tax": "稅",
    "taxIncluded": "含税",
    "total": "支付金額(已含稅)",
    "totalAmount": "合計金額",
    "totalLengthOfStay": "入住天數",
    "totalPriceWithTax": "含稅總價",
    "depositAmount": "訂金金額({0}%)",
    "remainAmount": "剩餘需付金額",
    "withTax": "含稅",
    "depositNecessary": "您需要通過我們的線上系統支付{0}%的訂金來完成預約。"
  },
  "reservationPayment": {
    "creditCardPayment": "使用信用卡即時付費",
    "onSitePayment": "入住時付費(無需事先支付)",
    "paymentMethod": "付款方式",
    "pendingPage": {
      "launchButton": "3D驗證",
      "text01": "點擊此按鈕，轉至3D驗證的密碼輸入頁面。",
      "text02": "若未在15分鐘以內完成密碼輸入，預約處理將會被取消。",
      "text03": "若未輸入您的密碼，預約將無法完成。空房可能會被其他顧客預約。",
      "text04": "信用卡支付由tripla代為處理，故3D驗證的店舖名顯示「tripla」。",
      "timeOutError01": "發生超時錯誤。",
      "timeOutError02": "一般情況下您無需支付任何費用。如信用卡已有支付紀錄，相應金額也將退還。詳細到帳時間請向信用卡公司確認。",
      "authError01": "支付處理失敗。",
      "error01": "預約處理過程中出現一個錯誤。",
      "error02": "一般情況下您無需支付任何費用。如信用卡已有支付紀錄，相應金額也將退還。詳細到帳時間請向信用卡公司確認。",
      "error03": "點擊「上一頁」按鈕，在搜索頁面再次預約。"
    },
    "planError": "非常抱歉，已选择的方案有相冲突的支付方式，请再次尝试。",
    "selectCreditCards": "請選擇要使用的信用卡",
    "sessionErrorMessage": "出現錯誤，請返回「顧客詳細資料」頁面並重試。",
    "sessionErrorButtonText": "返回「顧客詳細資料」",
    "gmoCreditCardMaintenanceWarning": "由於系統維護，此卡品牌將於2024年11月18日4:00至6:00（日本時間）無法提供服務。請選擇其他付款方式或等待維護完成。",
    "gmoOnSiteCreditCardRequiredMaintenanceWarning": "由於系統維護，2024年11月18日4:00至6:00（日本時間）將無法使用登錄信用卡的服務。請選擇其他付款方式或等待維護完成。",
    "gmoMcpMaintenanceWarning": "2024年11月18日4:00至6:00（日本時間），由於系統維護，將不接受VISA/Mastercard信用卡。請選擇其他付款方式或等待維護完成。"
  },
  "reservationReceipt": {
    "customerName": "收據抬頭",
    "deferredWithoutDownload": "因為公司代付，所以不提供發票。",
    "download": "下載付款收據",
    "downloadOnCheckout": "您可在退房當日或之後下載訂房確認。",
    "downloadAtSpecificTime": "您可以在 {date} 的 {time} 以後隨時下載收據。",
    "onSite": "若選擇當地支付費用時, 前台會為您發行收據。"
  },
  "searchBar": {
    "addRoom": "加訂一間客房",
    "adult": "{n}位大人",
    "adults": "大人",
    "age": "年齡",
    "agree": "同意",
    "bed": "加床鋪",
    "checkIn": "入住日期",
    "checkInAndOut": "入住日期 - 退房日期",
    "checkOut": "退房日期",
    "child": "{n}位兒童",
    "childN": "兒童 {n}",
    "children": "兒童",
    "childrenAgeAtCheckout": "退房時兒童的年齡",
    "childrenAgeInfant": "年齡 {from}-{to}",
    "childrenAgePrimary": {
      "primary": "年齡 {from}-{to}",
      "primaryBottom": "年齡 {from}-{to}",
      "primaryTop": "年齡 {from}-{to}"
    },
    "childrenAges": "同行兒童年齡",
    "childrenAgeSharedBed": "年齡 {from}-{to}",
    "childrenMealBedding": {
      "meal_no": "不含用餐和床鋪",
      "meal_no_bedding": "含床鋪",
      "meal_same_as_adult_bedding": "含用餐和床鋪",
      "meal_yes": "含用餐",
      "meal_yes_bedding": "含用餐和床鋪"
    },
    "cookieConsent": "此網站使用cookies來提高用戶體驗。若繼續使用此網站，則表示您已經同意我們使用cookies。若希望瞭解更多詳情，請查看{0}。",
    "privacyPolicy": "隱私條例",
    "perRoomGuests": "一間房入住的人數",
    "dates": "住宿期間",
    "dayUse": "搜尋日宿方案",
    "defaultArea": "全部地區",
    "edit": "更改",
    "filter": "選擇條件",
    "guests": "入住人數",
    "hotel": "飯店",
    "maxChildrenWarning": "每位大人最多可攜帶{n}位兒童。",
    "pleaseSelectHotel": "請選擇飯店名",
    "room": "{n} 間房間",
    "rooms": "房間數",
    "search": "開始搜尋",
    "showOnlyAvailable": "僅顯示可預約的房型/方案",
    "sort": "自行排序",
    "sortOption": {
      "price_high_to_low": "按價格排序 (從高至低)",
      "price_low_to_high": "按價格排序(從低至高)",
      "recommended": "按推薦排序",
      "asc": "按價格排序(從低至高)",
      "desc": "按價格排序 (從高至低)"
    },
    "to": "至",
    "undated": "日期未定",
    "nights": "入住天數",
    "yearOld": "{n} 歲",
    "enableWBFSearch": "有效搜尋住宿+機票方案",
    "stayFlightTab": "住宿+機票",
    "stayTab": "住宿",
    "processInJapTxt": "預約流程將以日文進行。",
    "processMaxTxt": "預約流程將以日文進行，最大預約房間數為5。",
    "undatedWarning": "若每間客房的人數不一樣，則無法在不輸入入住日期的情況下，搜索空房。",
    "undatedWarning2": "因為您已選擇搜索附機票的房間，這個無法啟動。",
    "flightAndRoomsWarning": "因為您已選擇搜索日期未定的房間，這個無法啟動。"
  },
  "searchReservation": {
    "enterDetail": "請輸入您的電子郵箱與預約編號",
    "enterReservationNumber": "請輸入您的預約號碼",
    "manageBooking": "住宿預約確認・取消",
    "searchFailed": "我們需要以下的資訊進行下一步。很抱歉，電子郵箱或預約號碼不正確。請再試一次。",
    "confirmReservation": "預約確認",
    "confirmReservationDesc": "因為此預約是在{ota}上支付，將在{time}秒後重新引導至{ota}。"
  },
  "searchResultByPlan": {
    "hide": "隱藏",
    "planContent": "方案",
    "plans": "方案",
    "rooms": "房型",
    "showPlan": "顯示方案詳情",
    "moreDetail": "顯示更多"
  },
  "snsLogin": {
    "loginWith": "以{0}登錄",
    "enterPassword": "請輸入您的密碼以繼續",
    "existingPrompt": "你好像有一個現有帳戶。請問要將您的帳戶與{sns}連接嗎？您之後可以使用{sns}或電子郵件/密碼來登錄。",
    "signUpDescription": "非常感謝您連接您的 {sns} 帳號, 請提供下述的資訊以完成帳號註冊。"
  },
  "voucher": {
    "cancel": "取消變更",
    "confirm": "確認",
    "number": "預約號碼",
    "purchseComplete": "已購買",
    "selectStay": "請選擇入住日期",
    "stillVoucher": "目前此住宿折扣券仍未使用, 并沒有任何預約客房的紀錄及其入住/退房日。",
    "thankPurchase": "感謝您購買住宿折扣券。",
    "update": "確定變更",
    "updateItinerary": "變更預約",
    "updateItineraryDesc": "請更改住宿日期。點擊「確定變更」的按鈕，確定變更。",
    "usingVoucher": "使用此住宿折扣券預約客房",
    "valid": "有效的住宿折扣券",
    "voucherDesc": "您可以使用此住宿折扣券預約客房, 請在此選擇入住日及退房日。",
    "voucherDetails": "住宿折扣券詳情",
    "voucherNumber": "住宿折扣券代碼"
  },
  "triplaPay": {
    "notFoundMsg": "未找到付款記錄。此交易將無法完成",
    "isPaid": "本次支付成功完成",
    "isAlreadyPaid": "已付款",
    "isFailed": "本次支付未能成功",
    "isCompleted": "本次支付成功完成",
    "isRefunded": "您的支付已退還",
    "refundedAt": "退款日期",
    "paidAt": "支付日期",
    "isCancelled": "本次支付已取消",
    "total": "支付金額",
    "pleaseReload": "此任務未能在一小時內完成，請重新加載。",
    "reload": "重新加載",
    "subject": "支付主體",
    "amountIsChanged": "支付金額已變更，請點擊「更新」按鈕，重新加載。",
    "terms": "本交易系統由tripla (公司) 提供，請客人理解個人信息將與tripla (公司)共享的條款後，並同意tripla {0}、{1} 和{2}，",
    "paypalTerms": "信用卡交易會使用PayPal，使用詳情會記載在您的信用卡賬單上。",
    "temsOfUse": "使用規則",
    "privacyPolicy": "隱私權條款",
    "gdprPolicy": "GDPR政策",
    "pleaseScan": "請掃描二維碼"
  },
  "triplaPayIssues": {
    "INVALID_PARAMETER_SYNTAX": "請檢查您的輸入的資訊",
    "INVALID_EXPIRATION_YEAR": "請確認有效日期是否正確。",
    "Invalid_card_number": "請確認信用卡號碼。",
    "Others": "請檢查您的輸入的資訊"
  },
  "lottery": {
    "registerForLottery": "申請抽獎",
    "selectedDatesError": "所選的住宿期間應在此抽獎活動所設定的最少及最大的住宿天數範圍內（{0} ~ {1} 晚）。"
  },
  "recommenderWidget": {
    "previewText": "這是預覽模式，僅有效期5分鐘。請在5分鐘後再次點擊“預覽”。別忘了為實際的上線版本保存您的設置。"
  }
}